import {alertActions} from ".";
import {apiCall} from "./actionFunctions";
import {sessionActions} from "./sessionActions";

export const userActions = {
    CreateUser,
    GetUser,
    GetUserById,
    UpdateUser,
    DeleteUser,
}

function CreateUser(info) {
    //Handeled by session registration function
    // return apiCall("POST", "user", {info}, (data, dispatch) => {});
}

function GetUser(info="") {
    return apiCall("GET", "user", {params: info}, (data, dispatch) => {});
}

function GetUserById(info="") {
    return apiCall("GET", "user/id", {params: info}, (data, dispatch) => {});
}

function UpdateUser(firstName, lastName, email, phone = undefined) {
    return apiCall("PUT", "user", {firstName, lastName, email, phone}, (data, dispatch) => {
        const {message} = data
        if (message) {
            dispatch(alertActions.Success(message)) 
            setTimeout(() => dispatch(sessionActions.Logout()), 750);
        }
    });
}

function DeleteUser(info) {
    //No deleting accounts yet
    // return apiCall("DELETE", "user", {info}, (data, dispatch) => {});
}