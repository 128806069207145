import {apiCall, reroute} from "./actionFunctions";
import { alertActions } from "../Actions";

export const tierActions = {
    CreateTier,
    GetTier,
    GetTierById,
    UpdateTier,
    DeleteTier,
}

function CreateTier(info) {
    return apiCall("POST", "tier", {info}, (data, dispatch) => {
        const { message } = data;
        if(message) console.log(message)
    });
}
function GetTier(info="") {
    return apiCall("GET", "tier", {params: info}, (data, dispatch) => {
    });
}
function GetTierById(info="") {
    return apiCall("GET", "tier/id", {params: info}, (data, dispatch) => {
        const { message } = data;
        if(message) console.log(message)
    });
}
function UpdateTier(tierInfo, type) {
    return apiCall("PUT", `tier/${type}`, {tierInfo}, (data, dispatch) => {
        const { message } = data;
        if(message) {
            dispatch(alertActions.Success(message))
            reroute(type)
        }
    });
}
function DeleteTier(info) {
    return apiCall("DELETE", "tier", {info}, (data, dispatch) => {
        const { message } = data;
        if(message) console.log(message)
    });
}
