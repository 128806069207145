import React, { useState, Fragment } from "react";
import PropTypes from "prop-types";

const Modal = ({ isOpen, onClose, title = "Title", desc = "Description", Input, buttonText = "Button", buttonFunc = () => {} }) => {
    return (
        <Fragment>
            {isOpen ? (
                <div tabIndex="-1" aria-hidden={!isOpen} className="fixed inset-0 z-40 bg-gray-500 bg-opacity-50 flex items-start justify-center">
                    <div className="relative p-4 w-full max-w-2xl mt-40">
                        <div className="relative rounded-lg shadow bg-gray-700 overflow-y-auto max-h-[85vh]">
                            <div className="flex items-center justify-between p-4 md:p-5 border-b rounded-t border-gray-600">
                                <h3 className="text-xl font-semibold text-white">{title}</h3>
                                <button type="button" onClick={onClose} className="text-gray-400 bg-transparent rounded-lg text-sm w-8 h-8 ms-auto inline-flex justify-center items-center hover:bg-gray-600 hover:text-white">
                                    <svg className="w-3 h-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 14">
                                        <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6" />
                                    </svg>
                                    <span className="sr-only">Close modal</span>
                                </button>
                            </div>

                            <div className="p-4 md:p-5 space-y-4">
                                <p className="text-base leading-relaxed text-gray-400">{desc}</p>
                            </div>

                            <Input></Input>

                            <div className="flex items-center p-4 md:p-5 border-t rounded-b border-gray-600">
                                <button onClick={buttonFunc} data-modal-hide="default-modal" type="button" className="text-white focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center bg-blue-600hover:bg-blue-700 focus:ring-blue-800">
                                    {buttonText}
                                </button>
                                <button onClick={onClose} data-modal-hide="default-modal" type="button" className="py-2.5 px-5 ms-3 text-sm font-medium rounded-lg border focus:ring-gray-700 bg-gray-800 text-gray-400 border-gray-600 hover:text-white hover:bg-gray-700">
                                    Cancel
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            ) : null}
        </Fragment>
    );
};

export default Modal;

Modal.propTypes = {};

export { Modal };
