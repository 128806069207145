import { sessionConstants } from "../Redux/constants";
import { alertActions } from "../Actions";
import {redux, reroute, getUrl, apiCall} from "./actionFunctions";

export const sessionActions = {
    Login,
    LoginGoogle,
    oAuthPassport,
    Logout,
    Register,
    UpdateProfilePicture,
    SendResetEmail,
    CheckResetCode,
    ResetPassword,
    VerifyEmail,
    SendVerificationEmail,
}

function Register({ firstName, lastName, email, password }) {
    return apiCall("POST", "auth/register", {firstName, lastName, email, password}, (data, dispatch) => {
        const { message } = data;
        if(message) {
            dispatch(alertActions.Success(message))
            setTimeout(() => reroute("login"), 750);
        }
    });
}

function Login({ email, password, useCaptcha, captchaToken}) {
    return apiCall("POST", "auth/login", {email, password, useCaptcha, captchaToken}, (data, dispatch) => {
        const { message, userInfo, token } = data;
        if (token) {
            dispatch(alertActions.Success(message))
            if (userInfo) dispatch(redux(sessionConstants.LoginSuccess, {userInfo: userInfo, token: token}))
            setTimeout(() => reroute("account"), 750);
        }
    });
}

function LoginGoogle() {  //Using passport OAuth
    window.location.href = getUrl() + '/api/auth/google';
}

function oAuthPassport() {
    const queryParams = new URLSearchParams(window.location.search);
    return apiCall("GET", "auth/google/callback", {params: queryParams}, (data, dispatch) => {
        const { message, userInfo, token } = data;
        if (token) {
            dispatch(alertActions.Success(message))
            if (userInfo) dispatch(redux(sessionConstants.LoginSuccess, {userInfo: userInfo, token: token}))
            setTimeout(() => reroute("account"), 750);
        }
    });
}

function Logout(params = {}) {
    return apiCall("POST", "auth/logout", {params}, (data, dispatch) => {
        const { message } = data;
        if (message) reroute("login");
        dispatch(redux(sessionConstants.Logout, ""));
    });
}

function VerifyEmail(token, Logout) {
    return apiCall("PUT", "auth/email/verify", {token}, (data, dispatch) => {
        const { message } = data;
        if (message) {
            dispatch(alertActions.Success(message)) 
            setTimeout(() => Logout(), 750);
        }
    });
}

function SendVerificationEmail(email) {
    return apiCall("POST", "auth/email", {email}, (data, dispatch) => {});
}

function SendResetEmail(email) {
    return apiCall("POST", "auth/password", {email}, (data, dispatch) => {
        const { message } = data;
        if (message) dispatch(alertActions.Success(message)) 
    });
}

function CheckResetCode(email, code) {
    return apiCall("POST", "auth/password/verify", {email, code}, (data, dispatch) => {
        const { message, accessToken } = data;
        if (message) dispatch(alertActions.Success(message)) 
    });
}

function ResetPassword(token, password) {
    return apiCall("PUT", "auth/password", {token, password}, (data, dispatch) => {
        const { message } = data;
        if (message) {
            dispatch(alertActions.Success(message)) 
            setTimeout(() => reroute("login"), 750);
        }
    });
}

function UpdateProfilePicture(profilePicture) {
    return apiCall("PUT", "auth/file/profilePicture", {formData: profilePicture}, (data, dispatch) => {
        const { message, url } = data;
        if (url) {
            dispatch(redux(sessionConstants.UpdateProfilePicture, url))
        }
    });
}
