import React, { Fragment, useEffect, useState } from 'react';
import { userActions } from "../../Actions";
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import construction from '../../Images/construction.jpg';

const Construction = () => {
    return (
        <Fragment>
            <section className="h-full p-20 min-h-screen">
                <div className="flex flex-col items-center justify-center px-6 py-8 mx-auto ">
                    <div className="bg-white rounded-lg shadow  sm:w-4/5">

                        <div className="p-6 space-y-4 text-center">

                            <h1 className="text-xl font-bold leading-tight tracking-tight text-gray-900 ">
                                Under Construction
                            </h1>
                            <p className="text-sm font-medium text-gray-600">
                                We are working hard to get the website back up and running. Check back later!
                                If you need assistance, email <a className="text-sm font-medium text-blue-600" href="mailto:&#104;&#101;&#108;&#108;&#111;&#46;&#116;&#104;&#101;&#111;&#114;&#121;&#117;&#110;&#100;&#101;&#114;&#103;&#114;&#111;&#117;&#110;&#100;&#64;&#103;&#109;&#97;&#105;&#108;&#46;&#99;&#111;&#109;">
                                    &#104;&#101;&#108;&#108;&#111;&#46;&#116;&#104;&#101;&#111;&#114;&#121;&#117;&#110;&#100;&#101;&#114;&#103;&#114;&#111;&#117;&#110;&#100;&#64;&#103;&#109;&#97;&#105;&#108;&#46;&#99;&#111;&#109;
                                </a>
                            </p>
                            <img
                                src={construction}
                                alt="Image 1"
                                className="object-cover mx-auto w-128 h-auto rounded-lg border-4 border-white"
                            />
                        </div>
                        
                    </div>
                </div>
            </section>
        </Fragment>
    )
};

Construction.propTypes = {
};

const connection = connect((state) => ({}), {})(Construction);
export { connection as Construction };