import React, { Fragment, useState, useEffect } from "react";
import { connect } from "react-redux";

const Livestream = ({ id, subContent }) => {
    const [tab, setTab] = useState(1); // 1 for Tab 1, 2 for Tab 2

    const Youtube = () => {
        return (
            <Fragment>
                {id ? (
                    <Fragment>
                        <div className="flex justify-center">
                            {/* YouTube livestream embed */}
                            <iframe width="720" height="405" src={`https://www.youtube.com/embed/${id}?autoplay=1&modestbranding=1&showinfo=0&rel=0&controls=1&enablejsapi=1`} frameborder="0" allow="autoplay; encrypted-media" allowfullscreen></iframe>
                        </div>

                        <div className="flex justify-center mt-4">
                            {/* YouTube live chat embed */}
                            <iframe width="100%" height="500" src={`https://www.youtube.com/live_chat?v=${id}&embed_domain=localhost`} frameborder="0" allowfullscreen></iframe>
                        </div>
                    </Fragment>
                ) : (
                    <Fragment>
                        <h5 className="mt-6 text-md font-semibold text-gray-900 text-center">
                            We are not currently live, but if you're craving more Theory Underground you can find our videos{" "}
                            <a className="text-blue-500 underline" href="https://www.youtube.com/@theory_underground" target="_blank">
                                here
                            </a>
                        </h5>
                    </Fragment>
                )}
            </Fragment>
        );
    };

    const Twitch = () => {
        return (
            <Fragment>
                <div className="flex flex-col items-center p-4 space-y-4">
                    <div className="w-full max-w-4xl aspect-w-16 aspect-h-9">
                        <iframe src="https://player.twitch.tv/?channel=theory_underground&parent=localhost" height="480" width="100%" allowFullScreen className="rounded-lg" />
                    </div>
                    <div className="w-full max-w-4xl aspect-w-16 aspect-h-9">
                        <iframe src="https://www.twitch.tv/embed/theory_underground/chat?parent=localhost" height="480" width="100%" className="rounded-lg" />
                    </div>
                </div>
            </Fragment>
        );
    };

    const render = () => {
        if (tab === 1) {
            return Youtube();
        } else {
            return Twitch();
        }
    };

    return (
        <Fragment>
            {subContent.tiers === "f-platinum" || subContent.tiers === "f-gold" ? (
                <Fragment>
                    <div className="flex w-full">
                        <div onClick={() => setTab(1)} className={`flex-1 text-center p-4 cursor-pointer border-b-2 ${tab === 1 ? "border-blue-500 text-blue-500" : "border-gray-300 text-gray-900"} hover:bg-gray-100`}>
                            Youtube
                        </div>
                        <div onClick={() => setTab(2)} className={`flex-1 text-center p-4 cursor-pointer border-b-2 ${tab === 2 ? "border-blue-500 text-blue-500" : "border-gray-300 text-gray-900"} hover:bg-gray-100`}>
                            Twitch
                        </div>
                    </div>
                    {render()}
                </Fragment>
            ) : (
                <p className="text-sm font-medium text-gray-800 max-w-full">
                    Your current subscription does not have access to view this page. Become a Gold or Platinum Member and view exclusive livestreams!
                    <a className="text-blue-500" href="/subscribe">
                        {" "}
                        Subscribe
                    </a>
                </p>
            )}
        </Fragment>
    );
};
Livestream.propTypes = {};

const connection = connect((state) => ({}), {})(Livestream);
export { connection as Livestream };
