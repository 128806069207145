import React, { Fragment, useEffect, useState } from 'react';
import { tierActions, reroute } from "../../Actions";
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Dropdown, Input, Button } from '../GeneralComponents/Elements';

const Playlists = ({GetTier, UpdateTier}) => {
    const [tiers, setTiers] = useState();
    const [playlistNum, setPlaylistNum] = useState(0);
    const [playlists, setPlaylists] = useState([]);
    const [selectedTier, setSelectedTier] = useState('');

    useEffect(() => {
        const getTiers = async () => {
            if (!tiers) {
                const {tiers} = await GetTier({all: true});
                if(tiers) setTiers(tiers)
        }};
        getTiers();
    }, []);
    
    useEffect(() => {

        if(selectedTier) {
            if (typeof selectedTier.playlists === "string") selectedTier.playlists = JSON.parse(selectedTier.playlists)
            const playlist = selectedTier.playlists || undefined
            setPlaylistNum(playlist?.length || 0)
            setPlaylists(playlist)
        }
    }, [selectedTier]);

    const callback = (value) => {
        setSelectedTier(value)
    }

    useEffect(() => {
        setPlaylists(prevPlaylists => {
            const updatedPlaylists = prevPlaylists || []; // Default to an empty array if undefined
    
            if (playlistNum > updatedPlaylists.length) {
                // Add empty strings to extend the array
                return [...updatedPlaylists, ...Array(playlistNum - updatedPlaylists.length).fill("")];
            } else if (playlistNum < updatedPlaylists.length) {
                // Slice the array to shorten it
                return updatedPlaylists.slice(0, playlistNum);
            }
            return updatedPlaylists; // No changes needed if lengths match
        });
    }, [playlistNum]);

    const handlePlaylistChange = (value, index) => {
        setPlaylists(prevPlaylists => {
            const updatedPlaylists = [...(prevPlaylists || [])]; // Ensure a copy of the array
            updatedPlaylists[index] = value;  // Update the feature at the specific index
            return updatedPlaylists; // Return the updated features array
        });
    };

    const renderPlaylists = () => {
        return (
            <>
                {Array.from({ length: playlistNum }).map((_, index) => (
                    <Input
                        key={index}
                        id={`playlists-${index}`}
                        text={playlists ? playlists[index] : ""} // Default to empty if no playlist is set
                        title={`Playlist Embed ${index + 1}`}
                        placeholder={`PLlcbaQ1cp2TJhV6mGYbhTNlXKlYA7K8sp`}
                        required={true}
                        onChange={(value) => handlePlaylistChange(value, index)} // Update specific playlist
                    />
                ))}
            </>
        );
    };

    const handlePlaylistNum = (amount) => {
        if(playlistNum + amount >= 0) setPlaylistNum((prevPlaylistNum) => prevPlaylistNum + amount);
    };

    const submit = () => {
        const updatedTier = selectedTier
        if(updatedTier) {
            updatedTier.playlists = playlists || []
            UpdateTier(updatedTier, 'playlists')
        }
    };

    return (
        <Fragment>
            <section className="h-full p-20 min-h-screen">
                <div className="flex flex-col items-center justify-center px-6 py-8 mx-auto ">
                    <div className="bg-white rounded-lg shadow sm:w-4/5">
                        <div className="p-6 space-y-4 text-center">
                            <h1 className="text-xl font-bold leading-tight tracking-tight text-gray-900 ">
                                Playlists
                            </h1>

                            <p className="text-sm font-medium text-gray-600">
                                Select an access level to manage playlists
                            </p>
                            <div>
                                Tier: <Dropdown options={tiers} label={"Select a Tier"} selectCallback={callback}></Dropdown>
                            </div>
                            
                            <div className="flex justify-center gap-2">
                                Number of playlists: {playlistNum}
                                <Button id="increase_playlists" text={"-"} onClick={() => handlePlaylistNum(-1)}></Button>
                                <Button id="increase_playlists" text={"+"} onClick={() => handlePlaylistNum(1)}></Button>
                            </div>


                            {renderPlaylists()}

                            <Button id="submit" text={"Submit"} onClick={submit}></Button>

                        </div>
                    </div>
                </div>
            </section>
        </Fragment>
    )
};

Playlists.propTypes = {
    
};

const connection = connect((state) => ({}), {
    GetTier: tierActions.GetTier,
    UpdateTier: tierActions.UpdateTier,

})(Playlists);
export { connection as Playlists };