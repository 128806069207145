import React, { Fragment, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { sessionActions, stripeActions, subContentActions, reroute } from "../../Actions";

const Seminar = ({GetSubContent}) => {
    const [subInfo, setSubInfo] = useState(undefined);

    useEffect(() => {
        const fetchSubInfo = async () => {
            if (!subInfo) {
                const sub = await GetSubContent();
                if(sub) {
                    setSubInfo(sub.subData);
                } 
            }
        };
        fetchSubInfo();
    }, []);

    return (
        <Fragment>
            {/* <section className="w-3/5 mx-auto h-full pb-40 pt-20">
                <div className="flex flex-col items-center justify-center px-6 py-8 mx-auto ">
                    <div className="w-full bg-white rounded-lg md:mt-0 xl:p-0 w-4/5">
                        <div className="p-6 space-y-4 md:space-y-6 sm:p-8 ">
                            <h3 className="mb-4 mt-16 text-5xl font-bold text-gray-700 text-center">Your Playlists</h3>

                            <h1 className="text-xl font-bold leading-tight tracking-tight text-gray-700 md:text-2xl text-center">
                               Below are the playlists you have access to with your subscription.
                            </h1>
                            {subInfo && subInfo.length > 0 ? (
                            subInfo.map((sub, index) => (
                                <iframe
                                key={index} // Always add a key when rendering lists in React
                                className="mx-auto"
                                width="560"
                                height="315"
                                src={sub}
                                allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                                allowFullScreen
                                ></iframe>
                            ))
                            ) :                             
                            <h1 className="text-lg leading-tight tracking-tight text-gray-700 md:text-2xl text-center">
                                No subscriptions active. 
                                <a href="/subscribe" className="inline-flex text-white bg-blue-600 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center my-4 ml-2 font-bold"> Subscribe</a>
                            </h1>
                            }


                        </div>
                    </div>
                </div>
            </section> */}
        </Fragment>
    )
};

Seminar.propTypes = {
};

const connection = connect((state) => ({}), {
    GetSubContent: subContentActions.GetSubContent
})(Seminar);
export { connection as Seminar };