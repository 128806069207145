import React, { Fragment } from 'react';
import PropTypes from 'prop-types';

const Button = ({
    id = "Button",
    text = "Button",
    disabled = false, 
    onClick = () => {},
}) => {
    return (
        <Fragment>
            <button 
                id={id}
                disabled={disabled}
                className={`w-fit h-10 inline text-white focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-4 py-2.5 text-center ${disabled ? 'bg-blue-500 cursor-not-allowed' : 'bg-blue-600 hover:bg-blue-700'}`}
                onClick={() => onClick()}
            >
            {text}
            </button>
        </Fragment>
    );
};

Button.propTypes = {
    id : PropTypes.string,
    text : PropTypes.string,
    disabled : PropTypes.bool,
    onClick : PropTypes.func,
};

export { Button };