import React, { Fragment, useEffect, useState } from "react";
import { userActions } from "../../Actions";
import { connect } from "react-redux";
import PropTypes from "prop-types";

const Help = () => {
    return (
        <Fragment>
            <section className="h-full p-20 min-h-screen large-info">
                <div className="flex flex-col items-center justify-center px-6 py-8 mx-auto ">
                    <div className="bg-white rounded-lg shadow sm:w-11/12">
                        <div className="p-12 space-y-6">
                            <div className="text-center">
                                <h1 className="text-3xl font-bold">Need Assistance?</h1>
                                <p className="mt-2 text-gray-600">We're here to help.</p>
                            </div>
                            <div className="pb-2">
                                <h3 className="text-xl font-semibold pb-4">Account & Billing</h3>
                                <p className="ml-4">
                                    Manage your account settings and billing information by clicking your profile icon and selecting <strong>Your Profile</strong>. To update your personal details, click the edit icon in the top-right corner of your account panel. To manage billing information or view payment history, go to the <strong>Memberships</strong> section and click <strong>Update Billing Information</strong>.
                                </p>
                            </div>
                            <div className="pb-2">
                                <h3 className="text-xl font-semibold pb-4">Getting Access to Your Features</h3>
                                <p className="ml-4">
                                    Once you subscribe, your purchased content will be available automatically. You can view it on your <strong>Account</strong> page, using the navigation tabs on the left side. Access is instant, so you’ll never have to wait to get started.
                                </p>
                            </div>
                            <div className="pb-2">
                                <h3 className="text-xl font-semibold pb-4">Troubleshooting</h3>
                                <p className="ml-4">
                                    We’re always working to make our platform as smooth as possible, but as a small team, we prioritize content over design. If you spot a bug or have suggestions, we’d love to hear from you.{" "}
                                    <a href="/contact" className="text-blue-500 underline font-medium">
                                        Contact Us
                                    </a>{" "}
                                    to share your feedback.
                                </p>
                            </div>
                            <div className="pb-2">
                                <h3 className="text-xl font-semibold pb-4">Privacy & Security</h3>
                                <p className="ml-4">
                                    Your privacy and security are our top priorities. View our{" "}
                                    <a href="/privacy" className="text-blue-500 underline font-medium">
                                        Privacy Policy
                                    </a>{" "}
                                    and{" "}
                                    <a href="/terms" className="text-blue-500 underline font-medium">
                                        Terms of Service
                                    </a>{" "}
                                    to learn more about how we protect your information.
                                </p>
                            </div>
                            <div className="text-center">
                                <p> Still need help?{" "}
                                <a href="/contact" className="text-blue-500 underline font-medium">
                                    Contact Us
                                </a>
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </Fragment>
    );
};

Help.propTypes = {};

const connection = connect((state) => ({}), {})(Help);
export { connection as Help };
