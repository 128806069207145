import React, { Fragment, useEffect, useState } from 'react';
import { userActions } from "../../Actions";
import { connect } from 'react-redux';

import undergroundtheory from '../../Images/Books/undergroundtheory.png';
import timenergy from '../../Images/Books/timeenergy.png';
import capital from '../../Images/Books/capitaltimeenergy.png';

import PropTypes from 'prop-types';

const Publications = () => {
    return (
        <Fragment>
            <section className="h-full py-12 pb-20 relative">
                <div className="absolute top-0 left-0 right-0 bottom-0 bg-black opacity-40 z-0"></div>              
                <div className="relative gap-8 items-center py-8 px-12 mx-auto h-fit rounded-lg w-4/5 z-10">
                    <div className="relative z-20 p-5 max-w-5xl mx-auto">

                        <h2 className="mb-12 text-5xl text-center tracking-tight font-extrabold text-gray-100 drop-shadow-xl">TU BOOKS</h2>
                        <div className="md:grid md:grid-cols-3 gap-12">
                            <div className="relative">
                                <a href="https://www.amazon.com/Underground-Theory-David-McKerracher/dp/B0CH2CXSGN" className="absolute inset-0 border-4 border-white blur-sm"></a>
                                <div>
                                    <img 
                                        alt={"Underground Theory"} 
                                        src={undergroundtheory} 
                                        className="object-cover w-auto h-full shadow-md rounded-lg"
                                    />
                                </div>
                            </div>
                            <div className="relative">
                                <a href="https://www.amazon.com/TIMENERGY-Why-Have-Time-Energy/dp/B0CH28JL4N/ref=sr_1_1?crid=2MNN1UZG5HDTI&dib=eyJ2IjoiMSJ9.ZUzuevTXL0fl5TVg1up0qVPiuqaa63cb1TSRNJKyC6Q.h_7xeNAti7SCTb-NnwO_iN9_Z_g4SEBSg6ibcsrrMcY&dib_tag=se&keywords=timenergy&qid=1731442268&s=books&sprefix=timenergy%2Cstripbooks%2C119&sr=1-1" className="absolute inset-0 border-4 border-white blur-sm"></a>
                                <div>
                                    <img 
                                        alt={"Timenergy"} 
                                        src={timenergy} 
                                        className="object-cover w-auto h-full shadow-md rounded-lg"
                                    />
                                </div>
                            </div>
                            <div className="relative">
                                <a href="https://theoryunderground.com/product/capital-vs-timenergy-presale/" className="absolute inset-0 border-4 border-white blur-sm"></a>
                                <div>
                                    <img 
                                        alt={"Capital VS Timenergy"} 
                                        src={capital} 
                                        className="object-cover w-full h-full shadow-md rounded-lg"
                                    />
                                </div>
                            </div>
                        </div>

                        <h2 className="mb-12 mt-12 text-2xl text-center tracking-tight font-extrabold text-gray-300 drop-shadow-xl">Or <a className='text-blue-500 underline' href="https://www.audible.com/pd/Timenergy-Audiobook/B0D27T85HT">Listen through Audible</a></h2>

                    </div>
                </div>
            </section>
        </Fragment>
    )
};

Publications.propTypes = {
};

const connection = connect((state) => ({}), {})(Publications);
export { connection as Publications };