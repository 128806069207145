import React, { Fragment, useEffect, useState } from "react";
import { userActions } from "../../Actions";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import redbubble from "../../Images/redbubble.png";

const Merch = () => {
    useEffect(() => {
        const script1 = document.createElement("script");
        script1.src = "https://www.redbubble.com/assets/external_portfolio.js";
        script1.type = "text/javascript";
        script1.async = true;

        const script2 = document.createElement("script");
        script2.type = "text/javascript";
        script2.innerHTML = `
            new RBExternalPortfolio('www.redbubble.com', 'tourmstucon', 5, 3).renderIframe();
        `;

        document.body.appendChild(script1);
        script1.onload = () => {
            document.body.appendChild(script2);
        };

        // Cleanup on unmount
        return () => {
            document.body.removeChild(script1);
            document.body.removeChild(script2);
        };
    }, []);
    return (
        <Fragment>
            <section className="h-full py-12 pb-20 relative">
                <div className="absolute top-0 left-0 right-0 bottom-0 bg-black opacity-40 z-0"></div>

                <div className="relative gap-8 items-center py-8 px-12 mx-auto h-fit rounded-lg w-4/5 z-10">
                    <div className="relative z-20 p-5 max-w-5xl mx-auto">
                        <h2 className="mb-12 text-5xl text-center tracking-tight font-extrabold text-gray-100 drop-shadow-xl">MERCH</h2>
                        <p className="text-center font-bold md:text-lg text-gray-100 mb-14 drop-shadow-xl">Get some of our merch!</p>
                        <p className="text-center font-bold text-sm text-gray-400 mb-14 drop-shadow-xl">
                            This page has a temporary embed through RedBubble. To view the merch in more detail, visit my portfolio
                            <a href="https://www.redbubble.com/people/tourmstucon/shop?utm_campaign=external-portfolio&utm_medium=embedded&utm_source=TOURMSTUCON" target="_blank" rel="noopener noreferrer" className="text-blue-500 hover:underline">
                                {" "}
                                here.
                            </a>
                        </p>

                        <a href="https://www.redbubble.com/people/tourmstucon/shop?utm_campaign=external-portfolio&utm_medium=embedded&utm_source=TOURMSTUCON" target="_blank">
                            <img alt={"Event Map"} src={redbubble} className="object-cover w-auto m-16 mx-auto shadow-md rounded-lg" />
                        </a>

                        <script type="text/javascript" src="https://www.redbubble.com/assets/external_portfolio.js"></script>
                        <script id="rb-xzfcxvzx" type="text/javascript">
                            new RBExternalPortfolio('www.redbubble.com', 'tourmstucon', 5, 3).renderIframe();
                        </script>
                    </div>
                </div>
            </section>
        </Fragment>
    );
};

Merch.propTypes = {};

const connection = connect((state) => ({}), {})(Merch);
export { connection as Merch };
