import React, { Fragment, useEffect, useState } from "react";
import { quickActions } from "../../Actions";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { Area, Input, Button, Modal } from "../GeneralComponents/Elements";

const GoLive = ({ GoLive }) => {
    const [id, setId] = useState();

    const submit = (type) => {
        if (type === "live") {
            GoLive(id);
        } else {
            GoLive(undefined );
        }
    };

    return (
        <section className="h-full p-20 min-h-screen">
            <div className="flex flex-col items-center justify-center px-6 py-8 mx-auto ">
                <div className="bg-white rounded-lg shadow  sm:w-4/5">
                    <div className="p-6 space-y-4 ">
                        <h1 className="text-xl font-bold leading-tight tracking-tight text-gray-900 text-center">Go Live</h1>

                        <Input id={`liveid`} text={id} title={`Livestream Id`} placeholder={`jfKfPfyJRdk`} required={true} onChange={setId} />
                        <div className="flex gap-2">
                            <Button id="submit" text={"Go Live"} onClick={() => submit("live")}></Button>
                            <Button id="remove" text={"Remove Stream"} onClick={() => submit("remove")}></Button>
                        </div>
                        
                    </div>
                </div>
            </div>
        </section>
    );
};

GoLive.propTypes = {};

const connection = connect((state) => ({}), {
    GoLive: quickActions.GoLive,
})(GoLive);
export { connection as GoLive };
