import React, { Fragment, useEffect, useState } from 'react';
import { objectActions, reroute } from "../../Actions";
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { DisplayObject, Dropdown } from '../GeneralComponents/Elements';
import * as stripeObjects from './StripeObjects';

const EditObject = ({GetObject, UpdateObject, CreateObject, DeleteObject}) => {
    const [examples, setExamples] = useState();
    const [selectedAction, setSelectedAction] = useState("Select an action");
    const [selectedType, setSelectedType] = useState("Select a type");

    const [stripeData, setStripeData] = useState();

    useEffect(() => {
        setExamples({ ...stripeObjects });
    }, [stripeObjects]);

    useEffect(() => {
        const getStripeData = async () => {
            if (!stripeData) {
                const {stripe} = await GetObject();
                if(stripe) setStripeData(stripe)
        }};
        getStripeData();
    }, []);

    const ActionDropdown = () => {
        const actionTypes = ["Create", "Read", "Update", "Delete"]
        const actionSelect = (selection) => setSelectedAction(selection)
        return <Dropdown options={actionTypes} label={"Select an Action"} selectCallback={actionSelect}></Dropdown>
    }

    const OptionsDropdown = () => {
        const dataTypes = Object.keys(examples); 
        const optionSelect = (selection) => setSelectedType(selection);
        return (
            <Dropdown
                options={dataTypes}
                label={"Select a Type"}
                selectCallback={optionSelect}
            />
        );
    };
    
    const DisplayFields = () => {
        const example = examples?.[selectedType?.toLowerCase()]?.obj || {};
        const exampleEditable = examples?.[selectedType?.toLowerCase()]?.editable || [];
        const exampleCreation = examples?.[selectedType?.toLowerCase()]?.creation || [];
        const data = stripeData?.[selectedType?.toLowerCase()]?.data || null;

        return (
            <Fragment>
                <DisplayObject
                    example={example}
                    editableFields={exampleEditable}
                    creationFields={exampleCreation}
                    action={selectedAction}
                    data={data}
                    label={selectedType || null}
                    callback={objectCallback}
                />
            </Fragment>
        );
    };

    const objectCallback = (selected, type) => {
        switch(type) {
            case "Update":
                UpdateObject(selected, selectedType)
                break;
            case "Create":
                // Remove keys with empty objects
                const cleanedSelected = Object.fromEntries(
                    Object.entries(selected).filter(
                        ([_, value]) => !(typeof value === "object" && value !== null && Object.keys(value).length === 0)
                    )
                );
                CreateObject(cleanedSelected, selectedType);
                break;
            case "Delete":
                DeleteObject(selected, selectedType);
                break;
            default:
          } 
    };

    return (
        <Fragment>
            <section className=" h-full pb-20">
                <div className="flex items-center justify-center px-6 py-8 mx-auto ">
                    <div className=" bg-white rounded-lg shadow md:mt-0 w-4/5 xl:p-0">
                        <div className="p-6 space-y-4 md:space-y-6 sm:p-8">
                        <div>
                            <h3 className="mb-4 text-2xl font-semibold">Manage Stripe</h3>
                            <a href='https://docs.stripe.com/api/' className="font-light sm:text-lg text-gray-400">https://docs.stripe.com/api/</a>
                        </div>
                        
                            {examples ? ActionDropdown() : null}
                            {examples ? OptionsDropdown() : null} 
                            {selectedAction && selectedType ? DisplayFields() : null}
                        </div>
                    </div>
                </div>
            </section>
        </Fragment>
    )
};

EditObject.propTypes = {

};

const connection = connect((state) => ({}), {
    GetObject: objectActions.GetObject,
    UpdateObject: objectActions.UpdateObject,
    DeleteObject: objectActions.DeleteObject,
    CreateObject: objectActions.CreateObject,
})(EditObject);
export { connection as EditObject };