import React, { Fragment, useState, useEffect } from "react";
import { sessionActions, stripeActions, userActions, reroute, quickActions, subContentActions } from "../../Actions";
import { connect } from "react-redux";
import { useAuth } from "../../Context/AuthContext";
import { Input, Button, Modal, Notification } from "../GeneralComponents/Elements";
import { Alert } from "../GeneralComponents";
import blankProfilePicture from "../../Images/Blank_Profile_Picture.png";
import PropTypes from "prop-types";
import { SubmitFile, Playlists, Livestream } from "../Subscriber/";

const Account = ({ session, GetUserById, UpdateUser, UpdateProfilePicture, PortalSession, GetLive, GetSubContent }) => {
    const auth = useAuth();
    const [editing, setEditing] = useState(false);
    const [userInfo, setUserInfo] = useState(undefined);
    const [initialValues, setInitialValues] = useState(undefined);
    const [firstName, setFirstName] = useState("");
    const [lastName, setLastName] = useState("");
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("********");
    const [phone, setPhone] = useState("");
    const [displayPhone, setDisplayPhone] = useState("");
    const [showModal, setShowModal] = useState(false);
    const [picture, setPicture] = useState(null);
    const [profilePicture, setProfilePicture] = useState(null);
    const [subs, setSubs] = useState(null);
    const [tab, setTab] = useState("Personal Information");
    const [liveId, setLiveId] = useState();
    const [subContent, setSubContent] = useState();
    const [showNotification, setShowNotification] = useState(false);

    const tabs = ["Personal Information", "Livestream", "Playlists", "The Turn!"];

    useEffect(() => {
        const fetchUserInfo = async () => {
            if (!userInfo) {
                const userInfo = await GetUserById();
                if (userInfo?.data && userInfo?.data.length > 0) {
                    setUserInfo(userInfo?.data[0]);
                } else {
                    setUserInfo(undefined);
                }
                setSubs(userInfo?.subs);
            }
        };
        fetchUserInfo();

        const fetchSubContent = async () => {
            if (!subContent) {
                const subContent = await GetSubContent();
                setUserInfo(subContent);
                setSubContent(subContent);
            }
        };
        fetchSubContent();
    }, []);

    useEffect(() => {
        const getLiveId = async () => {
            if (!liveId) {
                const { liveId } = await GetLive();
                if (liveId && liveId.length > 0) setLiveId(liveId[0]?.live_id);
            }
        };
        getLiveId();
    }, []);

    useEffect(() => {
        try {
            let profilePictureURL = JSON.parse(session.userInfo).profilePicture;
            if (profilePictureURL) setProfilePicture(profilePictureURL);
        } catch (err) {
            return;
        }
    }, [session]);

    useEffect(() => {
        if (userInfo) {
            setFirstName(userInfo.first_name);
            setLastName(userInfo.last_name);
            setEmail(userInfo.email);
            setPhone(userInfo.phone);
            setDisplayPhone(phoneCheck(userInfo.phone));
        }
    }, [userInfo]);

    const openModal = () => setShowModal(true);
    const closeModal = () => setShowModal(false);
    const removeFile = () => setPicture(null);

    const toggleEdit = () => {
        setEditing(!editing);
        if (editing) {
            setFirstName(initialValues.firstName);
            setLastName(initialValues.lastName);
            setEmail(initialValues.email);
            setPhone(initialValues.phone);
        } else {
            setInitialValues({
                firstName: firstName,
                lastName: lastName,
                email: email,
                phone: phone,
            });
        }
    };

    const updateInformation = () => {
        UpdateUser(firstName, lastName, email, phone && phone.length > 0 ? phone : undefined);
    };

    const verifyEmail = () => {
        reroute("VerifyEmail");
    };

    const handleDrop = (e) => {
        e.preventDefault();
        if (e.dataTransfer.files.length > 0) {
            setPicture(e.dataTransfer.files);
        }
    };
    function handleDragOver(ev) {
        ev.preventDefault();
    }
    const handleFileChange = (e) => {
        if (e.target.files.length > 0) {
            setPicture(e.target.files);
        }
    };
    function uploadFile() {
        if (picture && picture.length > 0) {
            const formData = new FormData();
            for (let i = 0; i < picture.length; i++) {
                formData.append("files", picture[i]);
            }
            UpdateProfilePicture(formData);
        }
        closeModal();
        setPicture(null);
    }

    const formatPhone = (e) => {
        if (e) {
            const cleaned = e.replace(/\D/g, "");
            setPhone(cleaned);
            setDisplayPhone(phoneCheck(e));
        }
    };

    const phoneCheck = (value) => {
        if (value) {
            // Remove all non-digit characters
            const cleaned = value.replace(/\D/g, "");

            // Format the phone number as xxx-xxx-xxxx
            if (cleaned.length <= 3) return cleaned;
            if (cleaned.length <= 6) return cleaned.replace(/(\d{3})(\d{0,3})/, "$1-$2");
            return cleaned.replace(/(\d{3})(\d{3})(\d{0,4})/, "$1-$2-$3");
        }
    };

    const fileInputHTML = () => {
        return (
            <div className="w-4/5 m-auto pb-8">
                <p className="mb-8 text-sm text-gray-400 text-center">Uploading a picture of your face is highly encouraged. The quality of my responses will be better if I'm able to see the person I'm talking to.</p>

                <div
                    className="flex items-center justify-center"
                    onDrop={handleDrop}
                    onDragOver={handleDragOver}
                    onClick={() => document.getElementById("dropzone-file").click()} // Trigger input click
                >
                    <div className="flex flex-col items-center justify-center w-full h-4/5 border-2 border-dashed rounded-lg cursor-pointer hover:bg-gray-800 bg-gray-700 hover:bg-gray-100 border-gray-600 hover:border-gray-500 hover:bg-gray-600">
                        <div className="flex flex-col items-center justify-center pt-5 pb-6">
                            <svg className="w-8 h-8 mb-4 text-gray-400" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 20 16">
                                <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M13 13h3a3 3 0 0 0 0-6h-.025A5.56 5.56 0 0 0 16 6.5 5.5 5.5 0 0 0 5.207 5.021C5.137 5.017 5.071 5 5 5a4 4 0 0 0 0 8h2.167M10 15V6m0 0L8 8m2-2 2 2" />
                            </svg>
                            <p className="mb-2 text-sm text-gray-400">
                                <span className="font-semibold">Click to upload</span> or drag and drop
                            </p>
                            <p className="text-xs text-gray-400">PNG or JPG (Max Size: 5MB)</p>
                        </div>
                    </div>
                    <input id="dropzone-file" type="file" className="hidden" onChange={handleFileChange} />
                </div>

                {picture ? (
                    <div className="flex items-center justify-between bg-gray-700 p-4 mt-4 rounded-lg">
                        <div className="text-gray-200 font-medium">
                            <span className="font-semibold text-gray-400">Uploaded file:</span> <span className="text-gray-100">{picture[0].name}</span>
                        </div>
                        <button onClick={removeFile} className="text-red-500 hover:text-red-600 ml-4">
                            <svg xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 24 24" className="w-5 h-5">
                                <path d="M6 19c0 1.1.9 2 2 2h8c1.1 0 2-.9 2-2V7H6v12zM19 4h-3.5l-1-1h-5l-1 1H5v2h14V4z" />
                            </svg>
                        </button>
                    </div>
                ) : null}
            </div>
        );
    };

    const personalInfo = () => {
        return (
            <Fragment>
                <div className="flex items-center justify-between mb-4">
                    <h2 className="text-3xl font-extrabold text-gray-700">Personal Information</h2>
                    {editing ? null : (
                        <button className="text-gray-500 hover:text-gray-700 p-2 rounded-full focus:outline-none" onClick={() => toggleEdit()}>
                            <svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 -960 960 960" width="24px" fill="#434343">
                                <path d="M200-200h57l391-391-57-57-391 391v57Zm-80 80v-170l528-527q12-11 26.5-17t30.5-6q16 0 31 6t26 18l55 56q12 11 17.5 26t5.5 30q0 16-5.5 30.5T817-647L290-120H120Zm640-584-56-56 56 56Zm-141 85-28-29 57 57-29-28Z" />
                            </svg>
                        </button>
                    )}
                </div>

                <div className="space-y-6">
                    <div className="grid gap-4 md:grid-cols-2">
                        <Input id={"first_name"} text={firstName} title={"First Name"} placeholder={"John"} required={true} onChange={setFirstName} disabled={!editing} />
                        <Input id={"last_name"} text={lastName} title={"Last Name"} placeholder={"Doe"} required={true} onChange={setLastName} disabled={!editing} />
                        <Input id={"phone"} text={displayPhone} title={"Phone Number"} placeholder={"Ex. 123-456-7890"} onChange={formatPhone} disabled={!editing} />
                    </div>
                    <div className="border-b border-gray-300 pb-4">
                        <Input id={"email"} text={email} title={"Email"} placeholder={"john.doe@gmail.com"} required={true} onChange={setEmail} disabled={!editing} />
                    </div>
                    <div className="border-b border-gray-300 pb-4">
                        <Input id={"password"} text={password} title={"Password"} placeholder={"********"} type={"password"} required={true} onChange={setPassword} disabled={true} />
                        <button className="font-medium text-blue-600 m-2 text-sm hover:underline" onClick={() => reroute("ResetPassword")}>
                            Change Password?
                        </button>
                    </div>
                    <div className="border-b border-gray-300 pb-4">
                        <p className="block mb-2 text-sm font-semibold text-gray-900 mb-2">Memberships</p>

                        <div className="space-y-2 mb-8">
                            {" "}
                            {/* This will add spacing between each subscription name */}
                            {subs && subs.length > 0 ? (
                                subs.map((name, index) => (
                                    <Fragment key={index}>
                                        <p className="text-sm font-medium text-gray-800 truncate max-w-full">{name}</p>
                                    </Fragment>
                                ))
                            ) : (
                                <p className="text-sm font-medium text-gray-800 truncate max-w-full">
                                    No active subscriptions.
                                    <a className="text-blue-500" href="/subscribe">
                                        {" "}
                                        Upgrade
                                    </a>
                                </p>
                            )}
                        </div>

                        <div className="flex gap-2">
                            <Button id={"edit_account"} text={"Upgrade Membership"} onClick={() => reroute("subscribe")}></Button>
                            <button className="font-medium text-blue-600 m-2 text-sm hover:underline" onClick={() => PortalSession()}>
                                Update Billing Information
                            </button>
                        </div>
                    </div>
                </div>

                <div className="flex gap-2">
                    {editing ? (
                        <Fragment>
                            <Button id={"edit_account"} text={"Save Changes"} onClick={updateInformation}></Button>
                            <Button id={"cancel"} text={"Cancel"} onClick={toggleEdit}></Button>
                        </Fragment>
                    ) : null}
                </div>
            </Fragment>
        );
    };
    const livestream = () => {
        return <Livestream subContent={subContent} id={liveId}></Livestream>;
    };
    const playlists = () => {
        return <Playlists subContent={subContent}></Playlists>;
    };
    const submitWriting = () => {
        return <SubmitFile subContent={subContent}></SubmitFile>;
    };

    const renderTab = () => {
        switch (tab) {
            case "Personal Information":
                return personalInfo();
            case "Livestream":
                return livestream();
            case "Playlists":
                return playlists();
            case "The Turn!":
                return submitWriting();
            default:
                return personalInfo();
        }
    };

    return (
        <Fragment>
            {userInfo?.legacy ? <Notification legacyUser={userInfo?.legacy}></Notification> : null}
            <Modal isOpen={showModal} onClose={closeModal} title="Upload Profile Picture" desc="" Input={fileInputHTML} buttonText="Submit" buttonFunc={uploadFile}></Modal>
            {auth.clearance === "unverified" ? <Alert type="warning" context="Your email is unverified. You will be unable to access some features of this website until you verify your email address." action="Verify Now" actionFunction={verifyEmail}></Alert> : null}
            <section className="h-full pb-20">
                <div className="flex flex-col gap-4 md:flex-row items-stretch justify-center px-6 py-8 mx-auto min-h-screen">
                    {/* Sidebar */}
                    
                    <div className="w-full md:w-1/5 bg-white rounded-lg shadow max-w-sm md:self-start">
                        <div className="p-4">
                            <div className="flex flex-col items-center mt-2">
                                <div className="relative w-24 h-24 mb-3">
                                    {profilePicture ? <img className="w-full h-full rounded-full shadow-lg object-cover object-center" src={profilePicture} alt="Profile Picture" /> : <img className="w-full h-full rounded-full shadow-lg object-cover object-center" src={blankProfilePicture} alt="Profile" />}
                                    <button onClick={() => openModal()} className="absolute bottom-0 right-0 bg-white rounded-full p-1 shadow-lg cursor-pointer">
                                        <svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 -960 960 960" width="24px" fill="#434343">
                                            <path d="M204-318q-22-38-33-78t-11-82q0-134 93-228t227-94h7l-64-64 56-56 160 160-160 160-56-56 64-64h-7q-100 0-170 70.5T240-478q0 26 6 51t18 49l-60 60ZM481-40 321-200l160-160 56 56-64 64h7q100 0 170-70.5T720-482q0-26-6-51t-18-49l60-60q22 38 33 78t11 82q0 134-93 228t-227 94h-7l64 64-56 56Z" />
                                        </svg>
                                    </button>
                                </div>
                                <h5 className="mt-6 text-md font-semibold text-gray-900">
                                    {firstName} {lastName}
                                </h5>
                            </div>
                        </div>
                        <div className="p-2">
                            <div className="flex flex-col border-l border-gray-300">
                                {tabs.map((item) => (
                                    <button key={item} onClick={() => setTab(item)} className={`px-4 py-2 text-md font-semibold text-left ${tab === item ? "text-blue-500 border-blue-500" : "text-gray-900 border-transparent"} border-l-4 hover:border-blue-500 focus:outline-none`}>
                                        {item}
                                    </button>
                                ))}
                            </div>
                        </div>
                    </div>

                    {/* Main Content */}
                    <div className="md:w-4/5 bg-white rounded-lg shadow md:mt-0 sm:max-w-xl flex flex-col flex-grow">
                        <div className="p-6 md:space-y-6 sm:p-8 flex-grow">{renderTab()}</div>
                    </div>
                </div>
            </section>
        </Fragment>
    );
};

Account.propTypes = {
    GetUserById: PropTypes.func.isRequired,
    UpdateUser: PropTypes.func.isRequired,
};

const connection = connect((state) => ({ session: state.session }), {
    GetUserById: userActions.GetUserById,
    UpdateUser: userActions.UpdateUser,
    UpdateProfilePicture: sessionActions.UpdateProfilePicture,
    PortalSession: stripeActions.PortalSession,
    GetLive: quickActions.GetLive,
    GetSubContent: subContentActions.GetSubContent,
})(Account);
export { connection as Account };
