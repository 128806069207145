import React, { Fragment, useState, useEffect } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

const Alert = ({
        type = "info",
        context = "This is an alert.",
        action = undefined,
        actionFunction = undefined,
    }) => {

    const infoClasses = {
        t800: 'text-blue-800', t500: 'text-blue-500', b300: 'border-blue-300', bg50: 'bg-blue-50', bg800: 'bg-blue-800', hbg900: 'hover:bg-blue-900', hbg200: 'hover:bg-blue-200 ', fr200: 'focus:ring-blue-200', fr400: 'focus:ring-blue-400',
    }
    const dangerClasses = {
        t800: 'text-red-800', t500: 'text-red-500', b300: 'border-red-300', bg50: 'bg-red-50', bg800: 'bg-red-800', hbg900: 'hover:bg-red-900', hbg200: 'hover:bg-red-200 ', fr200: 'focus:ring-red-200', fr400: 'focus:ring-red-400',
    }
    const warningClasses = {
        t800: 'text-yellow-800', t500: 'text-yellow-500', b300: 'border-yellow-300', bg50: 'bg-yellow-50', bg800: 'bg-yellow-800', hbg900: 'hover:bg-yellow-900', hbg200: 'hover:bg-yellow-200 ', fr200: 'focus:ring-yellow-200', fr400: 'focus:ring-byellow400',
    }
    const successClasses = {
        t800: 'text-green-800', t500: 'text-green-500', b300: 'border-green-300', bg50: 'bg-green-50', bg800: 'bg-green-800', hbg900: 'hover:bg-green-900', hbg200: 'hover:bg-green-200 ', fr200: 'focus:ring-green-200', fr400: 'focus:ring-green-400',
    }

    const [visible, setVisible] = useState(true);
    const [classes, setClasses] = useState(infoClasses);
    useEffect(() => {
        const element = document.getElementById("alert-border-1");
        if(visible && element) element.scrollIntoView({ behavior: "smooth", block: "center" });
    }, [visible]);

    useEffect(() => {
        switch (type) {
            case "info": setClasses(infoClasses); break;
            case "danger": setClasses(dangerClasses); break;
            case "warning": setClasses(warningClasses); break;
            case "success": setClasses(successClasses); break;
            default: setClasses(infoClasses); 
        }
    }, []);

    const dismiss = () => {
        setVisible(false);
    };

    const performAction = () => {
        dismiss();
        actionFunction();
    };

    return (
        <Fragment>
            {visible ? 
                <div id="alert-border-1" className={`flex items-center p-4 mb-4 ${classes.t800} border-t-4 ${classes.b300} ${classes.bg50} mx-auto w-11/12`} role="alert">
                    <svg className={`flex-shrink-0 w-4 h-4`} aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
                        <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5ZM9.5 4a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3ZM12 15H8a1 1 0 0 1 0-2h1v-3H8a1 1 0 0 1 0-2h2a1 1 0 0 1 1 1v4h1a1 1 0 0 1 0 2Z"/>
                    </svg>
                    <div className={`ms-3 text-sm font-medium mr-5`}>
                        {context}
                    </div>
                    <div className="ms-auto flex items-center space-x-2"> 
                        {action ? 
                            <button type="button" className={`text-white ${classes.bg800} ${classes.hbg900} focus:ring-4 focus:outline-none ${classes.fr200} font-medium rounded-lg text-xs px-3 py-1.5 me-2 text-center inline-flex items-center`} onClick={() => performAction()}>
                                {action}
                            </button>
                        : null}
                        <button type="button" className={`ms-auto -mx-1.5 -my-1.5 ${classes.bg50} ${classes.t500} rounded-lg focus:ring-2 ${classes.fr400} p-1.5 ${classes.hbg200} inline-flex items-center justify-center h-8 w-8`} data-dismiss-target="#alert-border-1" aria-label="Close" onClick={() => dismiss()}>
                            <span className={`sr-only`}>Dismiss</span>
                            <svg className={`w-3 h-3`} aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 14">
                                <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"/>
                            </svg>
                        </button>
                    </div>
                </div>
            : null }
        </Fragment>
    )
};

Alert.propTypes = {
    type: PropTypes.string,
    context: PropTypes.string,
    action: PropTypes.object,
    actionFunction: PropTypes.func,
};

const connection = connect((state) => ({}), {})(Alert);
export { connection as Alert };