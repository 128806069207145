import {apiCall} from "./actionFunctions";

export const subscriptionActions = {
    CreateSubscription,
    GetSubscription,
    GetSubscriptionById,
    UpdateSubscription,
    DeleteSubscription,
}

function CreateSubscription(info) {
    return apiCall("POST", "subscription", {info}, (data, dispatch) => {
        const { message } = data;
        if(message) console.log(message)
    });
}
function GetSubscription(info="") {
    return apiCall("GET", "subscription", {params: info}, (data, dispatch) => {
        const { message } = data;
        if(message) console.log(message)
    });
}
function GetSubscriptionById(info="") {
    return apiCall("GET", "subscription/id", {params: info}, (data, dispatch) => {
        const { message } = data;
        if(message) console.log(message)
    });
}
function UpdateSubscription(info) {
    return apiCall("PUT", "subscription", {info}, (data, dispatch) => {
        const { message } = data;
        if(message) console.log(message)
    });
}
function DeleteSubscription(info) {
    return apiCall("DELETE", "subscription", {info}, (data, dispatch) => {
        const { message } = data;
        if(message) console.log(message)
    });
}
