import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

const PageNotFound = ({ access }) => {

    return (
        <Fragment>
            <section className="h-full w-full m-20 min-h-screen mx-auto">
                <div className="items-center justify-center w-11/12 mx-auto sm:w-3/5">
                    <div className="bg-white rounded-lg shadow">
                            {access === "denied" ? 
                                <div className="mx-auto p-8">
                                    <div className="mx-auto text-center">
                                        <p className="mb-4 text-3xl tracking-tight font-bold text-gray-900 md:text-4xl">Access Denied</p>
                                        <p className="mb-4 text-lg font-light text-gray-500">You do not have access to view this page. Try logging in if you have not already. </p>
                                        <a href="/login" className="inline-flex text-white bg-blue-600 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center my-4">Login</a>
                                    </div>   
                                </div>
                            : 
                                <div className="mx-auto p-8">
                                    <div className="mx-auto text-center">
                                        <h1 className="mb-4 text-7xl tracking-tight font-extrabold  text-blue-600">404</h1>
                                        <p className="mb-4 text-3xl tracking-tight font-bold text-gray-900 md:text-4xl">Something is missing.</p>
                                        <p className="mb-4 text-lg font-light text-gray-500">Sorry, we can not find that page. You will find lots to explore on the home page. </p>
                                        <a href="/" className="inline-flex text-white bg-blue-600 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center my-4">Back to Homepage</a>
                                    </div>   
                                </div>
                            }
                    </div>
                </div>
            </section>
        </Fragment>
    )
};

PageNotFound.propTypes = {
    access: PropTypes.string.isRequired,
};

const connection = connect((state) => ({}), {})(PageNotFound);
export { connection as PageNotFound };