import React, { Fragment } from 'react';
import PropTypes from 'prop-types';

const Input = ({
    id = "input_id",
    text,
    title = "Input Title",
    placeholder = "",
    type = "text", 
    required = false,
    disabled = false,
    onChange = () => {},
}) => {
    return (
        <Fragment>
            <div>
                <label htmlFor={id} className="block mb-2 text-sm font-semibold text-gray-900">{title}</label>
                <input 
                    type={type} 
                    id={id} 
                    className={`text-sm rounded-lg w-full p-2.5 focus:ring-blue-500 focus:border-blue-500 
                        ${disabled ? 'bg-gray-100 text-gray-600 cursor-not-allowed' : 'bg-gray-50 border border-gray-300 text-gray-900'}`}
                    placeholder={placeholder} 
                    required={required} 
                    value={text}
                    disabled={disabled}
                    onChange={(e) => onChange(e.target.value)} 
                />
            </div>
        </Fragment>
    );
};

Input.propTypes = {
    id : PropTypes.string,
    text : PropTypes.string,
    title : PropTypes.string,
    placeholder : PropTypes.string,
    type : PropTypes.string,
    required : PropTypes.bool,
    disabled : PropTypes.bool,
    onChange : PropTypes.func,
};

export { Input };