import React, { createContext, useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';

const ButtonContext = createContext();

const ButtonProvider = ({ children }) => {
    const [lastClickTime, setLastClickTime] = useState(0);
    const clickDelay = 500; // 500ms delay between button clicks

    useEffect(() => {
        const handleClick = (e) => {
            const currentTime = Date.now();
            if (currentTime - lastClickTime < clickDelay) {
                e.preventDefault(); // Prevent default action (click action)
                e.stopImmediatePropagation(); // Prevent the event from propagating
            } else {
                setLastClickTime(currentTime); // Update last click time
            }
        };

        // Attach the click handler globally
        document.addEventListener('click', handleClick);

        // Cleanup the event listener on component unmount
        return () => {
            document.removeEventListener('click', handleClick);
        };
    }, [lastClickTime]);

    return (
        <ButtonContext.Provider value={{}}>
            {children}
        </ButtonContext.Provider>
    );
};

ButtonProvider.propTypes = {
    children: PropTypes.node.isRequired,
};

export { ButtonProvider };

export const useButton = () => {
    return useContext(ButtonContext);
};
