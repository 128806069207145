import React, { Fragment, useEffect, useState } from "react";
import { tierActions, subscriptionActions, stripeActions, reroute } from "../../Actions";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import {} from "../../Actions";
import { useAuth } from "../../Context/AuthContext";

const subscriptionCards = ({ CheckoutSession, cart, tiers, auth }) => {
    const capitalizeWords = (str) => str.replace(/\b\w/g, (char) => char.toUpperCase());
    return (
        <Fragment>
            {tiers
                ? tiers.map(({ description, features, name, price_id, amount }, index) => (
                      <div key={index} className="flex flex-col p-6 text-center rounded-lg border shadow border-gray-600 w-2/5 min-w-72 bg-gray-800 text-white">
                          <h3 className="mb-4 text-2xl font-semibold">{capitalizeWords(name)} Membership</h3>
                          <p className="font-light sm:text-lg text-gray-400">{description}</p>
                          <div className="flex justify-center items-baseline my-8">
                              <span className="mr-2 text-5xl font-extrabold">${amount / 100}</span>
                              <span className="text-gray-400">/month</span>
                          </div>

                          <div className="flex-grow">
                              <ul role="list" className="mb-8 space-y-4 text-left">
                                  {JSON.parse(features).map((feature, index) => (
                                      <li key={index} className="flex items-center space-x-3">
                                          <svg className="flex-shrink-0 w-5 h-5 text-green-400" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                                              <path fillRule="evenodd" d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z" clipRule="evenodd"></path>
                                          </svg>
                                          <span>{feature}</span>
                                      </li>
                                  ))}
                              </ul>
                          </div>

                          <button className="mt-auto bg-blue-500 focus:ring-4 font-medium rounded-lg text-sm px-5 py-2.5 text-center text-white focus:ring-primary-900" onClick={() => (auth?.clearance ? CheckoutSession(price_id, cart) : reroute("login"))}>
                              Get Started
                          </button>
                      </div>
                  ))
                : null}
        </Fragment>
    );
};
// const addonCards = ({cart, cartCallback,}) => {
//     let addons = [
//         {
//             title: "The Mikey Seminar",
//             subTitle: "Unlock access",
//             price: "100",
//             checkoutData: "price_1QLAQhK9wLlAVWAQiN9wVBly",
//         },
//         {
//             title: "The David McKerracher Seminar",
//             subTitle: "Unlock access",
//             price: "100",
//             checkoutData: "price_1QLAR9K9wLlAVWAQKO0BlLva",
//         },
//         {
//             title: "The Benjamin Studebaker Seminar",
//             subTitle: "Unlock access",
//             price: "100",
//             checkoutData: "price_1QLARWK9wLlAVWAQnSLAg2zA",
//         },
//         {
//             title: "The Nina Power Seminar",
//             subTitle: "Unlock access",
//             price: "100",
//             checkoutData: "price_1QLARvK9wLlAVWAQOHm5qznY",
//         },
//     ]

//     return (
//         <Fragment>
//             {addons.map(({ title, subTitle, price, checkoutData }, index) => (
//                 <div
//                     key={index}
//                     className={`h-full flex flex-col p-6 m-auto rounded-lg border shadow border-gray-600 bg-gray-800 text-white w-full relative ${
//                         cart.includes(checkoutData) ? 'opacity-50' : ''
//                     }`}
//                     onClick={() => cartCallback(checkoutData)} // Trigger callback on card click
//                 >
//                     {cart.includes(checkoutData) && (
//                         <div className="absolute top-0 left-0 right-0 bottom-0 bg-gray-500 bg-opacity-50 flex justify-center items-center rounded-lg">
//                             <span className="text-green-500 text-4xl">&#10003;</span> {/* Green checkmark */}
//                         </div>
//                     )}
//                     <h5 className="mb-2 text-lg font-semibold tracking-tight text-white text-center mt-auto">
//                         {title}
//                     </h5>
//                     <div className="flex justify-center items-baseline my-4 mt-auto">
//                         <span className="mr-2 text-lg font-extrabold">${price}</span>
//                         <span className="text-gray-400 ">/month</span>
//                     </div>

//                     <p className="font-normal text-gray-400 text-center mb-10">{subTitle}</p>

//                     <button
//                         type="submit"
//                         className={`mt-auto bg-gray-500 mt-4 focus:ring-4 font-medium rounded-lg text-sm px-5 py-2.5 text-center text-white focus:ring-primary-900 ${
//                             cart.includes(checkoutData) ? 'bg-green-500 cursor-not-allowed' : 'bg-gray-500'
//                         }`}
//                         disabled={cart.includes(checkoutData)} // Disable button if item is in cart
//                     >
//                         {cart.includes(checkoutData) ? 'Added to Checkout' : 'Add to Checkout'}
//                     </button>
//                 </div>

//             ))}
//         </Fragment>
//     )
// }

const Subscribe = ({ CheckoutSession, GetTier, GetSubscription }) => {
    const auth = useAuth();
    const [cart, setCart] = useState([]);
    const [tiers, setTiers] = useState();

    useEffect(() => {
        const getTiers = async () => {
            if (!tiers) {
                const tierData = await GetTier();
                setTiers(tierData?.tiers);
            }
        };
        getTiers();
    }, []);

    // const cartCallback = (id) => {
    //     if (!cart.includes(id)) {
    //         setCart((prevCart) => [...prevCart, id]); // Add id to cart
    //     } else {
    //         setCart((prevCart) => prevCart.filter((item) => item !== id)); // Remove id from cart
    //     }
    // };

    return (
        <section>
            <div className="py-8 px-4 mx-auto max-w-screen-xl lg:py-16 lg:px-6">
                <div className="mx-auto max-w-screen-md text-center mb-8 lg:mb-12">
                    <h2 className="mb-4 text-4xl tracking-tight font-extrabold text-white">Subscribe</h2>
                </div>
                <div className="flex flex-wrap justify-center gap-12 w-4/5 mx-auto">{subscriptionCards({ CheckoutSession, cart, tiers, auth })}</div>
                <div className="pb-20"></div>
                {/* <h2 className="mb-4 text-2xl tracking-tight font-bold text-gray-100 text-center mt-10">Or Check Out These Course Addons</h2>
                <div className="grid grid-cols-4 mt-10 gap-6 w-11/12 m-auto">
                    {addonCards({cart, cartCallback})}
                </div> */}
            </div>
        </section>
    );
};

Subscribe.propTypes = {};

const connection = connect((state) => ({}), {
    CheckoutSession: stripeActions.CheckoutSession,
    PortalSession: stripeActions.PortalSession,
    GetSubscription: subscriptionActions.GetSubscription,
    GetTier: tierActions.GetTier,
})(Subscribe);
export { connection as Subscribe };
