import React, { Fragment, useEffect, useState } from 'react';
import { sessionActions } from "../../Actions";
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

const SubSuccess = ({Logout}) => {
    return (
        <Fragment>
            <section className="bg-gray-50 h-full pb-20">
                <div className="flex flex-col items-center justify-center px-6 py-8 mx-auto ">
                    <div className="w-full bg-white rounded-lg shadow md:mt-0 sm:max-w-md xl:p-0">
                        <div className="p-6 space-y-4 md:space-y-6 sm:p-8">
                            <h1 className="text-xl font-bold leading-tight tracking-tight text-gray-900 md:text-2xl">
                                You have successfully subscribed. 
                            </h1>
                            <p className="text-sm font-medium text-gray-600">
                                You will need to login again to update your access. Please logout here.
                            </p>
                            <button className="underline block px-4 py-2 text-sm text-white bg-blue-500 rounded" onClick={() => Logout()}>
                                Sign out
                            </button>
                        </div>
                    </div>
                </div>
            </section>
        </Fragment>
    )
};

SubSuccess.propTypes = {
    Logout: PropTypes.func.isRequired,

};

const connection = connect((state) => ({}), {
    Logout: sessionActions.Logout,
})(SubSuccess);
export { connection as SubSuccess };