import { sessionConstants } from "./constants";

const INITIALSTATE = {
    useCaptcha: localStorage.getItem('useCaptcha') || null,
    token: localStorage.getItem('token') || null,
    userInfo: localStorage.getItem('userInfo') || null,
};

//User management
export function session(state = INITIALSTATE, action) {
    switch (action.type) {
        case sessionConstants.LoginSuccess:
            try {
                localStorage.setItem('token', action.info.token)
                localStorage.setItem('userInfo', JSON.stringify(action.info.userInfo))
            } catch (err) {console.error("Couldn't save state to sessionStorage", err)}
            return {...state, token: action.info.token, userInfo: action.info.userInfo};
        case sessionConstants.Logout:
            localStorage.clear();
            return state;
        case sessionConstants.UpdateProfilePicture:
            let userInfo
            try {
                userInfo = JSON.parse(localStorage.getItem('userInfo')) || {};
                userInfo.profilePicture = action.info;
                localStorage.setItem('userInfo', JSON.stringify(userInfo));
            } catch (err) {console.error("Couldn't save state to sessionStorage", err)}
            return {...state, userInfo: JSON.stringify(userInfo)};
        default:
            return state;
    }
}