import { alertActions } from "../Actions";
import {redux, reroute, getUrl, apiCall} from "./actionFunctions";

export const quickActions = {
    Contact,
    SubmitFile,
    GoLive,
    GetLive,
}

function Contact(email, subject, message) {
    return apiCall("POST", "contact", {email, subject, message}, (data, dispatch) => {
        const { message } = data;
        if(message) {
            dispatch(alertActions.Success(message))
            setTimeout(() => reroute(""), 750);
        }
    });
}
function SubmitFile(subject, link) {
    return apiCall("POST", "submitFile", {subject, link}, (data, dispatch) => {
        const { message } = data;
        if(message) console.log(message)
    });
}
function GoLive(id) {
    return apiCall("POST", "golive", {id}, (data, dispatch) => {
        const { message } = data;
        if(message) {
            dispatch(alertActions.Success(message))
            setTimeout(() => reroute("account"), 750);
        }
    });
}
function GetLive() {
    return apiCall("GET", "getlive", {}, (data, dispatch) => {});
}
