import { alertConstants, sessionConstants } from "../Redux/constants";
import { alertActions } from "../Actions";

export async function handleApiRequest(dispatch, getState, endpoint, method, body, onApiSuccess) {
    const state = getState();
    dispatch(redux(sessionConstants.REQUEST, endpoint));
    dispatch(alertActions.Clear());
    let url = getUrl() + '/api/' + endpoint;

    if (body.params) {
        url += '?' + new URLSearchParams(body.params);
    }
    const options = {
        method: method,
        headers: {},
        credentials: 'include'
    };
    if (body.formData && body.formData instanceof FormData) { //Form Data
        options.body = body.formData;
    } else if (Object.keys(body).length !== 0 && body.params == undefined) { //Post Request
        options.body = JSON.stringify(body);
        options.headers["Content-Type"] = "application/json";
    } else { 
        options.headers["Content-Type"] = "application/json"; 
    }

    try {
        const res = await fetch(url, options);
        if (res.status === 429) {
            dispatch(alertActions.Error("Too many requests. Please try again later."));
            return; 
        }
        if (res.status === 401) {
            dispatch(alertActions.Error("Session expired. Please sign back in to continue."));
            return;
        }
        if (res.status === 404) {
            dispatch(alertActions.Error("Request not found. Please contact your administrator."));
            return;
        }
        if (res.status === 500) {
            dispatch(alertActions.Error("There was an error with the server. Please refresh and try again."));
            return;
        }
        const data = await res.json(); 
        const { err } = data;
        if (err) dispatch(alertActions.Error(err));
        if (typeof onApiSuccess === 'function') {
            onApiSuccess(data, dispatch);
        }
        return data; 
    } catch (err) {
        console.log("There was an error in the request", err);
        throw err; 
    }
};

export function apiCall(method, url, params = {}, onSuccess) {
    return async function Thunk(dispatch, getState) {
        const response = await handleApiRequest(dispatch, getState, url, method, params, (data) => {
            if (onSuccess) {
                onSuccess(data, dispatch); // Pass dispatch to the onSuccess callback
            }
        });
        return response; // Return the response for further chaining if needed
    };
}

export function redux(type, info) {
    return { type: type, info };
}

//Value is true/false to display the loading screen
export function Loading(value) {
    return (dispatch) => {
        dispatch(redux(alertConstants.Loading, value))
    }
}

export function reroute(path) {
    window.location.assign(`${window.location.origin}/${path}`);
}

export function getUrl() {
    return process.env.REACT_APP_ENV === 'dev' ? process.env.REACT_APP_DEV_SERVER_URL : process.env.REACT_APP_PROD_URL;
}

