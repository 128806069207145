import React, { Fragment, useEffect, useState } from 'react';
import { tierActions, objectActions } from "../../Actions";
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Dropdown, Input, Button } from '../GeneralComponents/Elements';

const Tiers = ({GetTier, GetObject, UpdateTier}) => {
    const [tiers, setTiers] = useState();
    const [stripeData, setStripeData] = useState();
    const [stripePrices, setStripePrices] = useState();
    const [featureNum, setFeatureNum] = useState(0);
    const [description, setDescription] = useState();
    const [features, setFeatures] = useState('');
    const [selectedTier, setSelectedTier] = useState('');
    const [selectedPrice, setSelectedPrice] = useState();
    const [makePublic, setMakePublic] = useState();

    useEffect(() => {
        const getTiers = async () => {
            if (!tiers) {
                const {tiers} = await GetTier({all: true});
                if(tiers) setTiers(tiers)
        }};
        getTiers();

        const getStripeData = async () => {
            if (!stripeData) {
                const {stripe} = await GetObject();
                if(stripe) setStripeData(stripe)
                if(stripe) setStripePrices(stripe.prices.data)
        }};
        getStripeData();
    }, []);

    useEffect(() => {
        if(selectedTier) {
            const features = JSON.parse(selectedTier.features) || undefined
            setFeatureNum(features?.length || 0)
            setFeatures(features)
            setDescription(selectedTier.description)
            setMakePublic(selectedTier.public === 1 ? true : false)
            let price = stripePrices.find((price) => price.id == selectedTier.price_id);
            setSelectedPrice(price)
        }
    }, [selectedTier]);

    const tierCallback = (value) => {
        setSelectedTier(value)
    }

    const priceCallback = (value) => {
        setSelectedPrice(value)
    }

    const publicCallback = (value) => {
        setMakePublic(value)
    }

    useEffect(() => {
        setFeatures(prevFeatures => {
            const updatedFeatures = prevFeatures || []; // Default to an empty array if undefined
    
            if (featureNum > updatedFeatures.length) {
                // Add empty strings to extend the array
                return [...updatedFeatures, ...Array(featureNum - updatedFeatures.length).fill("")];
            } else if (featureNum < updatedFeatures.length) {
                // Slice the array to shorten it
                return updatedFeatures.slice(0, featureNum);
            }
            return updatedFeatures; // No changes needed if lengths match
        });
    }, [featureNum]);
    

    const handleFeatureChange = (value, index) => {
        setFeatures(prevFeatures => {
            const updatedFeatures = [...(prevFeatures || [])]; // Ensure a copy of the array
            updatedFeatures[index] = value;  // Update the feature at the specific index
            return updatedFeatures; // Return the updated features array
        });
    };
    

    const renderFeatures = () => {
        return (
            <>
                {Array.from({ length: featureNum }).map((_, index) => (
                    <Input
                        key={index}
                        id={`features-${index}`}
                        text={features ? features[index] : ""} // Default to empty if no feature is set
                        title={`Feature ${index + 1}`}
                        placeholder={`Access to...`}
                        required={true}
                        onChange={(value) => handleFeatureChange(value, index)} // Update specific feature
                    />
                ))}
            </>
        );
    };

    const handleFeatureNum = (amount) => {
        if(featureNum + amount >= 0) setFeatureNum((prevFeatureNum) => prevFeatureNum + amount);
    };

    const submit = () => {
        if(selectedTier && description && JSON.stringify(features) && selectedPrice) {
            UpdateTier({public: makePublic, selectedTier: selectedTier, description: description, features: JSON.stringify(features), price: selectedPrice.id, amount: selectedPrice.unit_amount}, 'tiers')
        }
    };

    return (
        <Fragment>
            <section className="h-full p-20 min-h-screen">
                <div className="flex flex-col items-center justify-center px-6 py-8 mx-auto ">
                    <div className="bg-white rounded-lg shadow  sm:w-4/5">
                        <div className="p-6 space-y-4 ">
                            <h1 className="text-xl font-bold text-center leading-tight tracking-tight text-gray-900 ">
                                Tiers
                            </h1>
                            <p className="text-sm font-medium text-gray-600">
                                Select a tier to manage its display
                            </p>

                            <div>
                                Tier: <Dropdown options={tiers} label={"Select a Tier"} selectCallback={tierCallback}></Dropdown>
                            </div>

                            <div>
                                Price: <Dropdown value={selectedPrice} options={stripePrices} label={"Select a price"} selectCallback={priceCallback}></Dropdown>
                            </div>
                            <p className="text-sm font-medium text-gray-600">
                                Amount: ${selectedPrice ? selectedPrice.unit_amount / 100 : ""}
                            </p>
                            <Input id={"description"} text={description || ""} title={"Description"} placeholder={"For people who... (255 character max)"} required={true} onChange={setDescription}/>
                    
                            <div className="flex gap-2">
                                Number of features: {featureNum}
                                <Button id="increase_features" text={"-"} onClick={() => handleFeatureNum(-1)}></Button>
                                <Button id="increase_features" text={"+"} onClick={() => handleFeatureNum(1)}></Button>
                            </div>


                            {renderFeatures()}

                            <div>
                                Public: <Dropdown value={makePublic} options={["true", "false"]} label={"Select if public"} selectCallback={publicCallback}></Dropdown>
                            </div>

                            <Button id="submit" text={"Submit"} onClick={submit}></Button>

                        </div>
                    </div>
                </div>
            </section>
        </Fragment>
    )
};

Tiers.propTypes = {
    
};

const connection = connect((state) => ({}), {
    GetTier: tierActions.GetTier,
    GetObject: objectActions.GetObject,
    UpdateTier: tierActions.UpdateTier,
})(Tiers);
export { connection as Tiers };