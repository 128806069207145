import React, { Fragment, useEffect, useState } from "react";
import { quickActions } from "../../Actions";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { Area, Input, Button, Modal } from "../GeneralComponents/Elements";

const SubmitFile = ({ Submit, subContent }) => {
    const [subject, setSubject] = useState("");
    const [message, setMessage] = useState("");
    const [picture, setPicture] = useState(null);
    const [showModal, setShowModal] = useState(false);
    const [link, setLink] = useState();

    const [subjectCount, setSubjectCount] = useState(0);
    const [messageCount, setMessageCount] = useState(0);
    const [linkCount, setLinkCount] = useState(0);

    const maxLength = {
        subject: 100,
        message: 300,
        link: 150,
    };

    const submit = () => {
        if (subjectCount <= maxLength.subject && linkCount <= maxLength.link) {
            Submit(subject, link);
        }
    };

    const handleDrop = (e) => {
        e.preventDefault();
        if (e.dataTransfer.files.length > 0) {
            setPicture(e.dataTransfer.files);
        }
    };
    function handleDragOver(ev) {
        ev.preventDefault();
    }
    const handleFileChange = (e) => {
        if (e.target.files.length > 0) {
            setPicture(e.target.files);
        }
    };
    function uploadFile() {
        if (picture && picture.length > 0) {
            const formData = new FormData();
            for (let i = 0; i < picture.length; i++) {
                formData.append("files", picture[i]);
            }
            //PerformAction(formData)
        }
        closeModal();
        setPicture(null);
    }

    const openModal = () => setShowModal(true);
    const closeModal = () => setShowModal(false);
    const removeFile = () => setPicture(null);

    // const fileInputHTML = () => {
    //     return (
    //         <div className="w-4/5 m-auto pb-8">
    //             <p className="mb-8 text-sm text-gray-400 text-center">Upload the file with the text we will review.</p>

    //             <div
    //                 className="flex items-center justify-center"
    //                 onDrop={handleDrop}
    //                 onDragOver={handleDragOver}
    //                 onClick={() => document.getElementById("dropzone-file").click()} // Trigger input click
    //             >
    //                 <div className="flex flex-col items-center justify-center w-full h-4/5 border-2 border-dashed rounded-lg cursor-pointer hover:bg-gray-800 bg-gray-700 hover:bg-gray-100 border-gray-600 hover:border-gray-500 hover:bg-gray-600">
    //                     <div className="flex flex-col items-center justify-center pt-5 pb-6">
    //                         <svg className="w-8 h-8 mb-4 text-gray-400" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 20 16">
    //                             <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M13 13h3a3 3 0 0 0 0-6h-.025A5.56 5.56 0 0 0 16 6.5 5.5 5.5 0 0 0 5.207 5.021C5.137 5.017 5.071 5 5 5a4 4 0 0 0 0 8h2.167M10 15V6m0 0L8 8m2-2 2 2" />
    //                         </svg>
    //                         <p className="mb-2 text-sm text-gray-400">
    //                             <span className="font-semibold">Click to upload</span> or drag and drop
    //                         </p>
    //                         <p className="text-xs text-gray-400">PNG, JPG, DOCX, PDF (Max Size: 5MB)</p>
    //                     </div>
    //                 </div>
    //                 <input id="dropzone-file" type="file" className="hidden" onChange={handleFileChange} />
    //             </div>

    //             {picture ? (
    //                 <div className="flex items-center justify-between bg-gray-700 p-4 mt-4 rounded-lg">
    //                     <div className="text-gray-200 font-medium">
    //                         <span className="font-semibold text-gray-400">Uploaded file:</span> <span className="text-gray-100">{picture[0].name}</span>
    //                     </div>
    //                     <button onClick={removeFile} className="text-red-500 hover:text-red-600 ml-4">
    //                         <svg xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 24 24" className="w-5 h-5">
    //                             <path d="M6 19c0 1.1.9 2 2 2h8c1.1 0 2-.9 2-2V7H6v12zM19 4h-3.5l-1-1h-5l-1 1H5v2h14V4z" />
    //                         </svg>
    //                     </button>
    //                 </div>
    //             ) : null}
    //         </div>
    //     );
    // };

    return (
        <Fragment>
            <h1 className="text-3xl text-center font-bold leading-tight tracking-tight pb-4 text-gray-900 ">The Turn!</h1>

            {subContent.tiers === "f-platinum" ? (
                <Fragment>
                    <p className="text-md font-medium pb-5 text-gray-600">As a Platinum Member, you can submit a file that we will personally read and provide comments on. You can send in one file each month. Ensure your share link has the correct permissions for us to view and comment on the file.</p>

                    <div className="pb-4">
                        <Input
                            id={`subject`}
                            text={subject}
                            title={`Subject`}
                            placeholder={`A short description of your submission`}
                            required={true}
                            onChange={(value) => {
                                setSubject(value);
                                setSubjectCount(value.length);
                            }}
                        />
                        <p className="text-sm text-gray-400 text-right">
                            {subjectCount}/{maxLength.subject}
                        </p>
                        {subjectCount > maxLength.subject && <p className="text-sm text-red-400 text-right">Max character count exceeded.</p>}
                    </div>

                    <div className="pb-4">
                        <Area
                            id={`message`}
                            text={message}
                            title={`Your Message`}
                            placeholder={`A brief message about your submission`}
                            required={true}
                            onChange={(value) => {
                                setMessage(value);
                                setMessageCount(value.length);
                            }}
                        />
                        <p className="text-sm text-gray-400 text-right">
                            {messageCount}/{maxLength.message}
                        </p>
                        {messageCount > maxLength.message && <p className="text-sm text-red-400 text-right">Max character count exceeded.</p>}
                    </div>

                    <div className="pb-4">
                        <Input
                            id={`link`}
                            text={link}
                            title={`Google Doc Share Link`}
                            placeholder={`https://docs.google.com/document/d/AWd23rfd3f...`}
                            required={true}
                            onChange={(value) => {
                                setLink(value);
                                setLinkCount(value.length);
                            }}
                        />
                        <p className="text-sm text-gray-400 text-right">
                            {linkCount}/{maxLength.link}
                        </p>
                        {linkCount > maxLength.link && <p className="text-sm text-red-400 text-right">Max character count exceeded.</p>}
                    </div>

                    <div className="flex gap-2">
                        <Button id="submit" text={"Submit"} onClick={submit}></Button>
                    </div>
                </Fragment>
            ) : (
                <p className="text-sm font-medium text-gray-800 max-w-full">
                    Your current subscription does not have access to view this page. Become a Platinum Member and have your work reviewed by the core team at Theory Underground!
                    <a className="text-blue-500" href="/subscribe">
                        {" "}
                        Subscribe
                    </a>
                </p>
            )}
        </Fragment>
    );
};

SubmitFile.propTypes = {};

const connection = connect((state) => ({}), {
    Submit: quickActions.SubmitFile,
})(SubmitFile);
export { connection as SubmitFile };
