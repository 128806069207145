import React, { Fragment, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Button } from './';
import { Dropdown } from './Dropdown';

const DisplayObject = ({
        example,
        action,
        editableFields,
        creationFields,
        data,
        label = "Default Label",
        callback = () => {},
    }) => {
    const [selected, setSelected] = useState();

    useEffect(() => {
        setSelected(null)
        if(action === "Create") {
            let cleaned = cleanFields(example, creationFields)
            setSelected(cleaned)
        }
    }, [action, example]);

    function cleanFields(data, fields) {    
        const processObject = (obj) => {
            const result = {};
            for (const key in obj) {
                if (fields.includes(key)) {
                    if (typeof obj[key] === "object" && obj[key] !== null && !Array.isArray(obj[key])) {
                        result[key] = processObject(obj[key]);
                    } else {
                        result[key] = null;
                    }
                }
            }
            return result;
        };
        return processObject(data);
    }

    const chooseDisplay = () => {
        const displays = {
            Create: createDisplay,
            Read: readDisplay,
            Update: updateDisplay,
            Delete: deleteDisplay,
        };
        return (displays[action] || viewExample)();
    };

    const createDisplay = () => {
        if(!selected) setSelected(example)
        return (
            <Fragment>
                <Fragment>
                        {selected ? render(selected, creationFields) : null}
                        <Button id="create_obj" text="Create" onClick={() => callback(selected, "Create")} />
                </Fragment>
            </Fragment>
        )};
    const readDisplay = () => {
        const fieldSelect = (selection) => setSelected(selection)
        return (
            <Fragment>
                <Dropdown options={data} label={"Select from " + label} selectCallback={fieldSelect}></Dropdown>
                {selected ? render(selected, [], false) : null}
            </Fragment>
        )}
    const updateDisplay = () => {
        const fieldSelect = (selection) => setSelected(selection)
        return (
            <Fragment>
                <Dropdown options={data} label={"Select from " + label} selectCallback={fieldSelect}></Dropdown>
                <Fragment>
                        {selected ? render(selected, editableFields) : null}
                        <Button id="update_obj" text="Update" onClick={() => callback(selected, "Update")} />
                </Fragment>
            </Fragment>
        )};
    const deleteDisplay = () => {
        const fieldSelect = (selection) => setSelected(selection)
        return (
            <Fragment>
                <Dropdown options={data} label={"Select from " + label} selectCallback={fieldSelect}></Dropdown>
                {selected ? 
                    <Fragment>
                        {render(selected, [], (updatedData) => setSelected(updatedData), false)}
                        <Button id="del_obj" text="Delete" onClick={() => callback(selected, "Delete")} />
                    </Fragment>
                : null}
            </Fragment>
        )};


    const render = (object, editableFields, edit = true) => {
        if (!object) return null;
        if (Array.isArray(object)) {
            return "To Do"
        }
        if (typeof object === "object" && object !== null) {
            return (            
            <RecursiveForm
                data={object}
                edit={edit}
                onUpdate={(updatedData) => setSelected(updatedData)}
                example={example}
                editable={editableFields}
            />
        )           
        }
        return "To Do"
    };


    const viewExample = (object) => {
        if (!object) return null;
        return (
            <div>     
            </div>
        );
    };

    return (
        <Fragment>
            {chooseDisplay()}
        </Fragment>
    );
};


const RecursiveForm = ({ example, data, edit, onUpdate, editable = [], parentKeyEditable = false }) => {
    // Use example structure if data is empty or not an object
    const resolvedData = data && typeof data === "object" ? data : {};

    const handleChange = (key, value) => {
        // Update the parent data when a field changes
        onUpdate({ ...resolvedData, [key]: value });
    };
    const isEditable = (key) => {
        return editable?.includes(key) || parentKeyEditable || false;
    };

    const getPlaceholder = (value) => {
        // If it's a string, return it directly without quotes
        if (typeof value === "string") {
            return value;
        }
        // Otherwise, stringify non-string values (e.g., objects, arrays)
        return JSON.stringify(value) || "";
    };

    return (
        <div className="p-6 m-auto mt-4 rounded-lg border shadow border-gray-600 bg-gray-800 text-white space-y-4">
            {Object.keys(example || resolvedData).map((key) => (
                <div key={key} className="space-y-4">
                    {typeof (resolvedData[key] || example[key]) === "object" &&
                    (resolvedData[key] || example[key]) !== null ? (
                        // Recursively render nested objects
                        <div className="ml-4">
                            <label className="font-bold text-gray-300 block mb-2 text-sm">{key}:</label>
                            <RecursiveForm
                                example={example?.[key] || {}}
                                data={resolvedData[key]}
                                edit={edit}
                                editable={editable}
                                parentKeyEditable={isEditable(key)} // Pass updated parentKeyEditable
                                onUpdate={(updatedChild) =>
                                    handleChange(key, updatedChild)
                                }
                            />
                        </div>
                    ) : (
                        // Render simple key-value pairs or placeholders from example
                        <div className="flex items-center space-x-4">
                            <label className="flex-none w-1/4 font-bold text-gray-300 break-words text-sm">
                                {key}:
                            </label>
                            <input
                                type="text"
                                name={key}
                                className={`flex-grow p-2 text-sm rounded ${
                                    edit
                                        ? "text-black border-gray-300"
                                        : "text-gray-100 bg-gray-800 border-b-2 border-gray-700"
                                }`}
                                value={resolvedData[key] || ""}
                                placeholder={edit ? getPlaceholder(example?.[key]) : ""}
                                disabled={!edit || !isEditable(key)} // Check editability
                                onChange={(e) =>
                                    handleChange(key, e.target.value)
                                }
                            />
                        </div>
                    )}
                </div>
            ))}
        </div>
    );
};





DisplayObject.propTypes = {

};

export { DisplayObject };