import React, { Fragment, useEffect, useState } from "react";
import { reroute, userActions } from "../../Actions";
import { connect } from "react-redux";
import background from "../../Images/herobackground.png";
import logoFull from "../../Images/LogoFull.jpg";
import PropTypes from "prop-types";

import person1 from "../../Images/People/person1.png";
import person2 from "../../Images/People/person2.png";
import person3 from "../../Images/People/person3.png";
import person4 from "../../Images/People/person4.png";
import person5 from "../../Images/People/person5.png";

import map from "../../Images/Events/EventMap.png";

const Home = () => {
    return (
        <Fragment>
            <section className="h-full py-12 pb-20 relative">
                <div>
                    <div>
                        <h2 className="mb-12 text-5xl text-center tracking-tight font-extrabold text-white drop-shadow-lg">Theory Underground</h2>
                        <div className="relative flex items-center justify-center w-4/5 mx-auto mb-20">
                            <button
                                onClick={() => {
                                    reroute("subscribe");
                                }}
                                className="absolute text-center text-xl font-bold text-white bg-gradient-to-r from-[#FA0FBA] to-[#0DD6DF] px-4 py-2 rounded-lg z-10"
                            >
                                Get Involved
                            </button>

                            <img alt={"Event Map"} src={map} className="object-cover w-auto m-16 mx-auto shadow-md rounded-lg" />
                        </div>
                    </div>
                </div>
            </section>
        </Fragment>
    );
};

Home.propTypes = {};

const connection = connect((state) => ({}), {})(Home);
export { connection as Home };
