import {apiCall} from "./actionFunctions";

export const subContentActions = {
    CreateAction,
    GetSubContent,
    GetActionById,
    UpdateAction,
    DeleteAction,
}

function CreateAction(info) {
    return apiCall("POST", "subContent", {info}, (data, dispatch) => {
        const { message } = data;
        if(message) console.log(message)
    });
}
function GetSubContent(info="") {
    return apiCall("GET", "subContent", {params: info}, (data, dispatch) => {});

}
function GetActionById(info="") {
    return apiCall("GET", "subContent/id", {params: info}, (data, dispatch) => {
        const { message } = data;
        if(message) console.log(message)
    });
}
function UpdateAction(info) {
    return apiCall("PUT", "subContent", {info}, (data, dispatch) => {
        const { message } = data;
        if(message) console.log(message)
    });
}
function DeleteAction(info) {
    return apiCall("DELETE", "subContent", {info}, (data, dispatch) => {
        const { message } = data;
        if(message) console.log(message)
    });
}
