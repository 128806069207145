import React, { Fragment, useEffect, useState } from 'react';
import { userActions } from "../../Actions";
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import AutisticSubject from '../../Images/Courses/autisticsubject.png';
import BeingAndTime from '../../Images/Courses/BeingAndTime.png';
import Doxology from '../../Images/Courses/doxology.png';
import Illich from '../../Images/Courses/illich.png';
import JacqueLacan from '../../Images/Courses/JacqueLacan.png';
import Marxism from '../../Images/Courses/marxism.png';
import Nickland from '../../Images/Courses/nickland.png';
import PMC from '../../Images/Courses/pmc.png';
import Zizek from '../../Images/Courses/zizek.png';

const Courses = () => {
    const courses = [
        {
            src: AutisticSubject, 
            alt: "AutisticSubject",
            tier: "",
            checkoutId: "",
            courseLink: "", 
        },
        {
            src: BeingAndTime,
            alt: "BeingAndTime",
            tier: "",
            checkoutId: "",
            courseLink: "", 
        },
        {
            src: Doxology,
            alt: "Doxology",
            tier: "",
            checkoutId: "",
            courseLink: "", 
        },
        {
            src: Illich,
            alt: "Illich",
            tier: "",
            checkoutId: "",
            courseLink: "", 
        },
        {
            src: JacqueLacan, 
            alt: "JacqueLacan", 
            tier: "",
            checkoutId: "",
            courseLink: "", 
        },
        {
            src: Marxism,
            alt: "Marxism",
            tier: "",
            checkoutId: "",
            courseLink: "", 
        },
        {
            src: Nickland,
            alt: "Nickland",
            tier: "",
            checkoutId: "",
            courseLink: "", 
        },
        {
            src: PMC,
            alt: "PMC",
            tier: "",
            checkoutId: "",
            courseLink: "", 
        },
        {
            src: Zizek,
            alt: "Zizek", tier: "",
            tier: "",
            checkoutId: "",
            courseLink: "", 
        },
    ]
    return (
        <Fragment>
            <section className="h-full py-12 pb-20 relative">
                <div className="absolute top-0 left-0 right-0 bottom-0 bg-black opacity-40 z-0"></div>               

                <div className="relative gap-8 items-center py-8 px-12 mx-auto h-fit rounded-lg w-4/5 z-10">
                    
                    <div className="relative z-20 p-5 max-w-5xl mx-auto">
                        
                        <h2 className="mb-12 text-5xl text-center tracking-tight font-extrabold text-gray-100 drop-shadow-xl">TU COURSES</h2>
                        <p className="text-center font-bold md:text-lg text-gray-100 mb-14 drop-shadow-xl">
                            Most courses prioritize the long-lost art of The Lecture... which is perfect for binging with earbufs while on the job. Courses can get unlocked via monthly subscriptions, premium access, or one-time fees (We punish you for not subscribing by changing an arm and a leg).
                        </p> 
                        <div className="md:grid md:grid-cols-2 gap-8">
                            {courses.map(({ src, alt, locked=false }, index) => (
                                <div key={index} className="relative w-auto h-72 overflow-hidden rounded-lg shadow-md mx-auto">
                                    <img 
                                        alt={alt} 
                                        src={src} 
                                        className="object-cover w-auto h-full shadow-md"
                                    />

                                    {locked && (
                                        <div className="absolute inset-0 flex items-center justify-center bg-gray-800 bg-opacity-60 z-10">
                                            <svg className='' xmlns="http://www.w3.org/2000/svg" height="42px" viewBox="0 -960 960 960" width="42px" fill="#FFFFFF"><path d="M240-80q-33 0-56.5-23.5T160-160v-400q0-33 23.5-56.5T240-640h40v-80q0-83 58.5-141.5T480-920q83 0 141.5 58.5T680-720v80h40q33 0 56.5 23.5T800-560v400q0 33-23.5 56.5T720-80H240Zm0-80h480v-400H240v400Zm240-120q33 0 56.5-23.5T560-360q0-33-23.5-56.5T480-440q-33 0-56.5 23.5T400-360q0 33 23.5 56.5T480-280ZM360-640h240v-80q0-50-35-85t-85-35q-50 0-85 35t-35 85v80ZM240-160v-400 400Z"/></svg>
                                        </div>
                                    )}
                                    
                                </div>
                                
                            ))}
                        </div>
                    </div>
                </div>
            </section>

        </Fragment>
    )
};

Courses.propTypes = {
};

const connection = connect((state) => ({}), {})(Courses);
export { connection as Courses };