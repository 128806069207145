import React, { Fragment, useEffect, useState } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";

const Notification = ({legacyUser}) => {
    // State to control if the notification should be shown
    const [showNotification, setShowNotification] = useState(false);
    const [dontShowAgain, setDontShowAgain] = useState(false);  // Track checkbox state
    const [legacy, setLegacy] = useState();  // Track checkbox state

    // Check if the cookie exists and set the state accordingly
    useEffect(() => {
        if (!getCookie("notification_disabled")) {
            setShowNotification(true); // Show notification if cookie isn't set
        }
        console.log(legacyUser)

        setLegacy(legacyUser)
    }, []);

    // Function to set a cookie that disables the notification
    const setNotificationCookie = () => {
        const expiryDate = new Date();
        expiryDate.setFullYear(expiryDate.getFullYear() + 1); // Cookie expires in 1 year
        document.cookie = `notification_disabled=true; expires=${expiryDate.toUTCString()}; path=/`;
    };

    // Function to get a specific cookie by name
    const getCookie = (name) => {
        const value = `; ${document.cookie}`;
        const parts = value.split(`; ${name}=`);
        if (parts.length === 2) return parts.pop().split(";").shift();
        return undefined;
    };

    // Handle the dismiss button click
    const handleDismiss = () => {
        if (dontShowAgain) {
            setNotificationCookie(); // Set cookie to disable notification if checkbox is checked
        }
        setShowNotification(false); // Hide the notification
    };

    return (
        legacy === 1 && (
            
            <div>
                {showNotification && (
                    <div id="notification">
                        <div
                            tabIndex="-1"
                            className="fixed inset-0 z-40 bg-gray-500 bg-opacity-50 flex items-start justify-center"
                        >
                            <div className="relative p-4 w-full max-w-2xl mt-40">
                                <div className="relative rounded-lg shadow bg-gray-700 overflow-y-auto max-h-[85vh]">
                                    <div className="flex items-center justify-between p-4 md:p-5 border-b rounded-t border-gray-600">
                                        <h3 className="text-xl font-semibold text-white">
                                            Active Subscriber Notification
                                        </h3>
                                        <button
                                            type="button"
                                            className="text-gray-400 bg-transparent rounded-lg text-sm w-8 h-8 ms-auto inline-flex justify-center items-center hover:bg-gray-600 hover:text-white"
                                            onClick={() => setShowNotification(false)}
                                        >
                                            <svg
                                                className="w-3 h-3"
                                                aria-hidden="true"
                                                xmlns="http://www.w3.org/2000/svg"
                                                fill="none"
                                                viewBox="0 0 14 14"
                                            >
                                                <path
                                                    stroke="currentColor"
                                                    strokeLinecap="round"
                                                    strokeLinejoin="round"
                                                    strokeWidth="2"
                                                    d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"
                                                />
                                            </svg>
                                            <span className="sr-only">Close modal</span>
                                        </button>
                                    </div>

                                    <div className="p-4 md:p-5 space-y-4">
                                        <p className="text-base leading-relaxed text-gray-200">
                                            Hi, we noticed you've subscribed on the previous version of the
                                            Theory Underground website. We are working hard to migrate all of
                                            our subscribers to the current website subscription tiers. Until
                                            then, you will soon be granted access to the equivalent subscription
                                            content on the current website based on your previous subscription
                                            tier. You will be contacted when action is needed from you. If you
                                            have any questions about this process, please{" "}
                                            <a href="/contact" className="text-blue-400 underline">
                                                reach out
                                            </a>{" "}
                                            and let us know!
                                        </p>
                                    </div>

                                    {/* Don't show again checkbox */}
                                    <div className="flex items-center p-4 md:p-5">
                                        <input
                                            type="checkbox"
                                            id="dontShowAgain"
                                            checked={dontShowAgain}
                                            onChange={(e) => setDontShowAgain(e.target.checked)}
                                            className="mr-2"
                                        />
                                        <label htmlFor="dontShowAgain" className="text-gray-300">
                                            Don't show me this again
                                        </label>
                                    </div>

                                    <div className="flex items-center p-4 md:p-5 border-t rounded-b border-gray-600">
                                        <button
                                            onClick={handleDismiss}
                                            type="button"
                                            className="text-white focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center bg-blue-600 hover:bg-blue-700 focus:ring-blue-800"
                                        >
                                            Dismiss
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                )}
            </div>
        )
    );
};
Notification.propTypes = {};

const connection = connect((state) => ({}), {})(Notification);
export { connection as Notification };
