import React, { Fragment, useState, useEffect } from 'react';
import PropTypes from 'prop-types';

const Dropdown = ({
    options = [],
    label = "Default Label",
    value = undefined,
    selectCallback = () => {}
}) => {
    const [isOpen, setIsOpen] = useState(false);
    const [selected, setSelected] = useState();

    useEffect(() => {
        if (value !== selected?.choice) {
            if(!value) {
                setSelected(undefined)
            } else {
                const formattedOption = formatOption(value, "default");
                setSelected({ choice: value, text: formattedOption });
            }
        }
    }, [value]);

    useEffect(() => {
        selectCallback(selected?.choice)
    }, [selected]);

    const getOptionType = (option) => {
        if (Array.isArray(option)) return "Array";
        if (typeof option === "object" && option !== null) return "Object";
        return "String";
    };
    
    const formatOption = (option, key) => {
        switch (getOptionType(option)) {
            case "Array":
                return `Array of items (${
                    option.length > 0 ? String(option[0]).slice(0, 10) : "Empty Array"
                }...)`;
            case "Object":
                return option.name || option.nickname || option.id || `Unnamed Object (${key})`;
            default:
                return String(option);
        }
    };

    const listItem = (option, key) => {
        const formattedOption = formatOption(option, key)
        return (
            <li key={key}>
                <button
                    onClick={() => {
                        setSelected(option != label ? {choice: option, text: formattedOption} : null)
                        setIsOpen(false);
                    }}
                    className="w-full text-left px-4 py-2 hover:bg-gray-100 focus:outline-none"
                >
                    {formattedOption}
                </button>
            </li>
        )};

    const renderOptions = () => {
        if (!options) return null;
        if (Array.isArray(options)) {
            return options.map((option, key) => listItem(option, key));
        }
        if (typeof options === "object" && options !== null) {
            return Object.keys(options).map((key) =>
                listItem({ name: key, value: options[key] }, key)
            );
        }
        return listItem(options, 0);
    };
    
    return (
        <div className="relative p-1 inline-block">
            <button onClick={() => setIsOpen(!isOpen)} className="bg-blue-600 text-white px-4 py-2 rounded hover:bg-blue-700 focus:outline-none">
                {selected?.text || label}
            </button>
            {isOpen && (
                <ul className="absolute z-10 bg-white border rounded shadow w-48 mt-2">
                    {listItem(label, "label-text")}
                    {renderOptions()}
                </ul>
            )}
        </div>
    );
};

Dropdown.propTypes = {

};

export { Dropdown };