import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import { useAuth } from '../../Context/AuthContext'; 
import {Loading} from './Elements/Loading';
import {PageNotFound} from './notfound';

import PropTypes from 'prop-types';

const ProtectedRoute = ({ allowed, children }) => {
    const auth = useAuth(); 

    // Show a loading state while auth is being determined
    if (auth === undefined || auth === null) {
        return <Loading></Loading> 
    }


    const isAllowed = allowed.includes(auth.clearance) || allowed.length === 0;

    if (!isAllowed) {
        return <PageNotFound access="denied" />;
    }

    return children; 
};


ProtectedRoute.propTypes = {
    allowed: PropTypes.array.isRequired,
    children: PropTypes.node.isRequired,
};

const connection = connect((state) => ({}), {})(ProtectedRoute);
export { connection as ProtectedRoute };