import React, { createContext, useContext } from 'react';
import { connect } from 'react-redux';
import { useJwt } from "react-jwt";
import PropTypes from 'prop-types';

const AuthContext = createContext();

const AuthProvider = ({ session, children }) => {
    let auth = undefined; 
    const { decodedToken, isExpired } = useJwt(session.token);
    if(!isExpired) {
        auth = decodedToken
    } else {
        auth = "Expired"
    }

    return (
        <AuthContext.Provider value={auth}>
            {children}
        </AuthContext.Provider>
    );
};

AuthProvider.propTypes = {
    session: PropTypes.shape({
        token: PropTypes.string,
    }).isRequired,
    children: PropTypes.node.isRequired,
};

const connection = connect((state) => ({session: state.session}), {})(AuthProvider);
export { connection as AuthProvider };

export const useAuth = () => {
    return useContext(AuthContext);
};
