import React, { Fragment, useEffect, useState } from "react";
import { quickActions } from "../../Actions";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { Area, Input, Button, Modal } from "../GeneralComponents/Elements";

const Playlists = ({ subContent }) => {
    const [playlistIds, setPlaylistIds] = useState([]);

    useEffect(() => {
        if (subContent) {
            setPlaylistIds(subContent.playlists);
        }
    }, [subContent]);

    return (
        <div className="p-4">
            <h2 className="text-2xl font-bold text-gray-800 mb-6">Your Playlists</h2>

            {playlistIds.length > 0 ? (
                <div className="flex flex-wrap gap-6 ">
                    {playlistIds.map((id, index) => (
                        <div key={index} className="flex flex-col w-full w-[100%] md:w-[47%] bg-gray-100 rounded-lg shadow hover:shadow-lg transition-shadow duration-200">
                            <iframe className="rounded-t-lg" width="%" height="200" src={`https://www.youtube.com/embed/?listType=playlist&list=${id}`} frameBorder="0" allowFullScreen />
                            {/* <div className="p-4">
                            <p className="text-sm text-gray-700 font-semibold">
                                Playlist {index + 1}
                            </p>
                        </div> */}
                        </div>
                    ))}
                </div>
            ) : (
                <p className="text-sm font-medium text-gray-800 max-w-full">
                    Your current subscription does not have access to view this page. Become a Member and view exclusive courses and seminars!
                    <a className="text-blue-500" href="/subscribe">
                        {" "}
                        Subscribe
                    </a>
                </p>
            )}
        </div>
    );
};

Playlists.propTypes = {};

const connection = connect((state) => ({}), {})(Playlists);
export { connection as Playlists };
