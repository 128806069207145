export * from './StripeObjects';

//https://docs.stripe.com/api/

export const customers = {
    id: "S-Customer",
    name: "Customers",
    obj: {
        id: "cus_NffrFeUfNV2Hib",
        object: "customer",
        address: {
          city: null,
          country: null,
          line1: null,
          line2: null,
          postal_code: null,
          state: null,
        },
        balance: 0,
        created: 1680893993,
        currency: null,
        default_source: null,
        delinquent: false,
        description: null,
        discount: null,
        email: "jennyrosen@example.com",
        invoice_prefix: "0759376C",
        invoice_settings: {
            "custom_fields": null,
            "default_payment_method": null,
            "footer": null,
            "rendering_options": null
        },
        livemode: false,
        metadata: {},
        name: "Jenny Rosen",
        next_invoice_sequence: 1,
        phone: null,
        preferred_locales: [],
        shipping: null,
        tax_exempt: "none",
        test_clock: null
    },
    editable: ["address", 'description', 'email', 'metadata', 'name', 'payment_method', 'phone', 'shipping', 'tax', 'balance', 'cash_balance', 'coupon', 'default_source', 'invoice_prefix', 'invoice_settings', 'next_invoice_sequence', 'preferred_locales', 'promotion_code', 'source', 'tax_exempt'],
    creation: ["address", 'description', 'email', 'metadata', 'name', 'payment_method', 'phone', 'shipping', 'tax', 'balance', 'cash_balance', 'coupon', 'invoice_prefix', 'invoice_settings', 'next_invoice_sequence', 'preferred_locales', 'promotion_code', 'source', 'tax_exempt', 'tax_id_data', 'test_clock']
}

export const prices = {
    id: "S-Price",
    name: "Prices",
    obj: {
        "id": "price_1MoBy5LkdIwHu7ixZhnattbh",
        "object": "price",
        "active": true,
        "billing_scheme": "per_unit",
        "created": 1679431181,
        "currency": "usd",
        "custom_unit_amount": null,
        "livemode": false,
        "lookup_key": null,
        "metadata": {},
        "nickname": null,
        "product": "prod_NZKdYqrwEYx6iK",
        "recurring": {
          "aggregate_usage": null,
          "interval": "month",
          "interval_count": 1,
          "trial_period_days": null,
          "usage_type": "licensed"
        },
        "tax_behavior": "unspecified",
        "tiers_mode": null,
        "transform_quantity": null,
        "type": "recurring",
        "unit_amount": 1000,
        "unit_amount_decimal": "1000"
      },
    editable: ['currency', 'active', 'metadata', 'nickname', 'product', 'recurring', 'tax_behavior', 'lookup_key', 'billing_scheme', 'currency_options', 'product_data', 'tiers', 'tiers_mode', 'transfer_lookup_key', 'transform_quantity'],
    creation: ['currency', 'active', 'metadata', 'nickname', 'product', 'recurring', 'tax_behavior', 'unit_amount', 'billing_scheme', 'currency_options', 'lookup_key', 'product_data', 'tiers', 'tiers_mode', 'transfer_lookup_key', 'transform_quantity']

}

export const products = {
    id: "S-Product",
    name: "Products",
    obj: {
        "id": "prod_NWjs8kKbJWmuuc",
        "object": "product",
        "active": true,
        "created": 1678833149,
        "default_price": null,
        "description": null,
        "images": [],
        "marketing_features": [],
        "livemode": false,
        "metadata": {},
        "name": "Gold Plan",
        "package_dimensions": null,
        "shippable": null,
        "statement_descriptor": null,
        "tax_code": null,
        "unit_label": null,
        "updated": 1678833149,
        "url": null
      },
    editable: ['name', 'active', 'description', 'metadata', 'tax_code', 'default_price_data', 'images', 'marketing_features', 'package_dimensions', 'shippable', 'statement_descriptor', 'unit_label', 'url'],
    creation: ['name', 'active', 'description', 'metadata', 'tax_code', 'default_price_data', 'images', 'marketing_features', 'package_dimensions', 'shippable', 'statement_descriptor', 'unit_label', 'url']
}
