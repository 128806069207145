import React, { Fragment, useEffect, useState } from 'react';
import { quickActions } from "../../Actions";
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Area, Input, Button } from '../GeneralComponents/Elements';

const Contact = ({Contact}) => {
    const [email, setEmail] = useState('');
    const [subject, setSubject] = useState('');
    const [message, setMessage] = useState('');

    const [emailCount, setEmailCount] = useState(0);
    const [subjectCount, setSubjectCount] = useState(0);
    const [messageCount, setMessageCount] = useState(0);

    const maxLength = {
        email: 100,
        subject: 100,
        message: 500
    };

    const submit = () => {
        if(emailCount <= maxLength.email && subjectCount <= maxLength.subject && messageCount <= maxLength.message) {
            Contact(email, subject, message)
        }
    };

    return (
        <Fragment>
            <section className="h-full p-10 sm:p-20 min-h-screen">
                <div className="flex flex-col items-center justify-center py-8 mx-auto ">
                    <div className="bg-white rounded-lg shadow sm:w-4/5">
                        <div className="p-12 space-y-4">

                            <h1 className="text-3xl text-center font-bold leading-tight tracking-tight pb-4 text-gray-900 ">
                                Contact Us
                            </h1>

                            <p className="text-md font-medium text-gray-600">
                                Got a technical issue? Want to send feedback about a beta feature? Need details about subscriptions, courses, seminars, events? Let us know. 
                            </p>

                            <p className="text-md font-medium pb-5 text-gray-600">
                                If you'd like to get feedback on your writing, become a <a className='text-blue-500 underline' href='/subscribe'>Premium Subscriber</a>
                                
                            </p>

                            <div className="pb-4"> 
                                <Input
                                    id={`email`}
                                    text={email}
                                    title={`Your Email`}
                                    placeholder={`name@email.com`}
                                    required={true}
                                    onChange={(value) => {
                                        setEmail(value);
                                        setEmailCount(value.length);
                                    }} 
                                />
                                <p className='text-sm text-gray-400 text-right'>{emailCount}/{maxLength.email}</p>
                                {emailCount > maxLength.email && (
                                    <p className='text-sm text-red-400 text-right'>
                                        Max character count exceeded.
                                    </p>
                                )}
                            </div>

                            <div className="pb-4">
                                <Input
                                    id={`subject`}
                                    text={subject} 
                                    title={`Subject`}
                                    placeholder={`Let us know how we can help you`}
                                    required={true}
                                    onChange={(value) => {
                                        setSubject(value);
                                        setSubjectCount(value.length);
                                    }} 
                                />
                                <p className='text-sm text-gray-400 text-right'>{subjectCount}/{maxLength.subject}</p>
                                {subjectCount > maxLength.subject && (
                                    <p className='text-sm text-red-400 text-right'>
                                        Max character count exceeded.
                                    </p>
                                )}
                            </div>

                            <div className="pb-4"> 
                                <Area
                                    id={`message`}
                                    text={message} 
                                    title={`Your Message`}
                                    placeholder={`Leave a comment...`}
                                    required={true}
                                    onChange={(value) => {
                                        setMessage(value);
                                        setMessageCount(value.length);
                                    }} 
                                />
                                <p className='text-sm text-gray-400 text-right'>{messageCount}/{maxLength.message}</p>
                                {messageCount > maxLength.message && (
                                    <p className='text-sm text-red-400 text-right'>
                                        Max character count exceeded.
                                    </p>
                                )}
                            </div>


                            <Button id="submit" text={"Submit"} onClick={submit}></Button>

                        </div>
                    </div>
                </div>
            </section>
        </Fragment>
    )
};


Contact.propTypes = {
};

const connection = connect((state) => ({}), {
    Contact: quickActions.Contact,
})(Contact);
export { connection as Contact };