import React, { Fragment, useEffect, useState } from 'react';
import { userActions } from "../../Actions";
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

const Admin = () => {
    return (
        <Fragment>
            <section className="h-full p-20 min-h-screen">
                <div className="flex flex-col items-center justify-center px-6 py-8 mx-auto ">
                    <div className="bg-white rounded-lg shadow  sm:w-4/5">
                        <div className="p-6 space-y-4 text-center">
                            <h1 className="text-xl font-bold leading-tight tracking-tight text-gray-900 ">
                                Admin Controls
                            </h1>
                            
                            <a href="/editobject" className="block px-4 py-2 text-sm text-blue-700 underline">Stripe</a>
                            <a href="/tiers" className="block px-4 py-2 text-sm text-blue-700 underline">Tiers</a>
                            <a href="/playlists" className="block px-4 py-2 text-sm text-blue-700 underline">Playlists</a>
                            <a href="/golive" className="block px-4 py-2 text-sm text-blue-700 underline">GoLive</a>

                        </div>
                    </div>
                </div>
            </section>
        </Fragment>
    )
};

Admin.propTypes = {
};

const connection = connect((state) => ({}), {})(Admin);
export { connection as Admin };